import {Box, Text, useToast} from "@chakra-ui/react";
import {ethers} from "ethers";
import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import NavigationBar from "../../components/NavigationBar";
import TabBar from "../../components/TabBar";
import WhiteSpace from "../../components/WhiteSpace";
import {THEME} from "../../config/theme";
import {useAppSelector} from "../../hooks/store";
import {
  useGetContractBalanceQuery,
  useTransactionsByAddressQuery
} from "../../reducer/chainSlice";
import {RootState} from "../../store/rootStore";
import TransactionItem from "./components/TransactionItem";

const TAB_OPTION = [
  {label: "全部", value: "all"},
  {label: "转出", value: "out"},
  {label: "转入", value: "in"},
  {label: "失败", value: "failed"},
];

const Transactions = (props: any) => {
  const [currentSelected, setCurrentSelected] = useState("all");
  const [listItems, setListItems] = useState([]);
  const navigate = useNavigate()
  const toast = useToast()
  const timer = useRef<any>(null);
  const {currentWallet, pendingTrans, currentContract} = useAppSelector(
    (rootState: RootState) => rootState.app
  );
  const [balance, setBalance] = useState(currentContract?.balance ?? '0')

  const {data: transactionsData, isFetching, refetch} = useTransactionsByAddressQuery({
    addressHash: currentWallet?.address,
    contractAddress: currentContract?.contractAddress || '',
  });

  const {data: balanceData, refetch: refetchBalance} = useGetContractBalanceQuery({
    address: currentWallet?.address || '',
    contract: currentContract?.contractAddress || '',
  })

  useEffect(() => {
    if (balanceData) {
      setBalance(ethers.utils.formatUnits(balanceData.result))
    }
  }, [balanceData, pendingTrans])

  const poll = () => {
    timer.current = setTimeout(() => {
      refetch()
      refetchBalance()
      poll()
    }, 3000)
  }

  useEffect(() => {
    poll()
    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [])

  useEffect(() => {

  }, [pendingTrans])


  useEffect(() => {
    if (transactionsData && transactionsData.result) {
      if (currentSelected === "all") {
        setListItems(transactionsData.result);
      } else if (currentSelected === "out") {
        setListItems(
          transactionsData.result.filter(
            (item: any) => item.from.toUpperCase() === currentWallet?.address?.toUpperCase()
          )
        );
      } else if (currentSelected === "in") {
        setListItems(
          transactionsData.result.filter(
            (item: any) => item.to.toUpperCase() === currentWallet?.address?.toUpperCase()
          )
        );
      } else if (currentSelected === "failed") {
        setListItems(
          transactionsData.result.filter((item: any) => !item.confirmations)
        );
      }
    }
  }, [transactionsData, currentSelected, currentWallet?.address]);

  const handleOnCopySuccess = () => {
    toast({
      title: "地址已经复制",
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
  };

  return (
    <div>
      <NavigationBar title={currentContract?.name}/>
      <Container>
        <Box className="card">
          <Text className="balance">{balance}</Text>
          <WhiteSpace height={2}/>
          <Text className="symbol">{currentContract?.symbol}</Text>
        </Box>
        <WhiteSpace/>
        <TabBar
          options={TAB_OPTION}
          selected={currentSelected}
          onChange={(value) => {
            setCurrentSelected(value);
          }}
        />
        {pendingTrans &&
          pendingTrans.map((item: any) => {
            return (
              <TransactionItem
                info={item}
                key={item.timeStamp}
                pending
              ></TransactionItem>
            );
          })}
        {listItems &&
          listItems.map((item: any) => {
            return (
              <TransactionItem
                info={item}
                key={item.timeStamp}
              ></TransactionItem>
            );
          })}
        <div className="float-btn">
          <div className="receive" onClick={() => {
            navigator.clipboard.writeText(currentWallet?.address || "").then(function () {
              handleOnCopySuccess()
            }, function (err) {
              console.error('Async: Could not copy text: ', err);
            });
          }}>收款
          </div>
          <div className="transform" onClick={() => {
            navigate("/my/select-token");
          }}>转账
          </div>
        </div>
        <WhiteSpace height={48 + 16}/>
      </Container>
    </div>
  );
};

export default Transactions;

const Container = styled.div`
  padding: 24px;

  .card {
    height: 88px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #131b63 -18.9%, #481162 119.73%);
    border-radius: 8px;
    color: white;

    .balance {
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      font-feature-settings: "case" on;
    }

    .symbol {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      text-align: center;
      font-feature-settings: "case" on;
    }
  }

  .float-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 768px;
    z-index: 999;
    display: flex;
    padding: 8px 24px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.8);
    border-top: 0.5px solid rgba(60, 60, 67, 0.36);
    backdrop-filter: blur(10px);
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    font-feature-settings: "case" on;
    margin: auto;

    .receive {
      height: 48px;
      width: 149px;
      background-color: #d975bb;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .transform {
      height: 48px;
      width: 149px;
      background-color: ${THEME.color.primary.default};
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
`;
