import {
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import * as React from "react";
import { useAppSelector } from "../../../hooks/store";
import { RootState } from "../../../store/rootStore";
import TokenItem from "./TokenItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands } from "@fortawesome/fontawesome-svg-core/import.macro";
import useTransfer from "../../../hooks/useTransfer";
import { useSearchParams } from "react-router-dom";
import { erc20Transfer } from "../../../utils/etherUtils";
import EncryptDiloag from "../../../components/EncryptDialog";
import useEncrypt from "../../../hooks/useEncrypt";
import { CircularProgress } from "@chakra-ui/react";
import useBalance from "../../../hooks/useBalance";

export interface ISendTokenProps {}

export default function SendToken(props: ISendTokenProps) {
  const {network, currentWallet } = useAppSelector(
    (state: RootState) => state.app
  );
  const [toAddress, setToAddress] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const contract = searchParams.get("contract");
  const erc20Tokens = currentWallet?.erc20Tokens || []
  const erc20Token = erc20Tokens?.find(
    (erc20) => erc20.contractAddress === contract
  );
  const balance = currentWallet?.balance || ''

  const { transfer, getGasPrice, gasPrice, gasPriceLoading } = useTransfer();
  const [loading, setLoading] = React.useState(false);

  const toast = useToast();

  const handleToAddressChange = (event: any) => {
    setToAddress(event.target.value);
  };

  const handleAmountChange = (event: any) => {
    setAmount(event.target.value);
  };

  const { showEncryptDialog, decryptPrivatekey } = useEncrypt();

  const handleConfirmPassword = async (password: string) => {
    setLoading(true);
    if (!erc20Token) {
      const response = await transfer(
        toAddress,
        amount,
        decryptPrivatekey(password, currentWallet?.privateKey || "")
      );
      console.log("response", response);
    } else {
      if (currentWallet) {
        const res = await erc20Transfer(
          erc20Token.contractAddress,
          network,
          decryptPrivatekey(password, currentWallet?.privateKey || ''),
          toAddress,
          amount
        );
        console.log("res", res);
      }
    }
    setLoading(false);
    toast({
      title: "Transfer succeed!",
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
  };

  const { updateBlance } = useBalance();

  React.useEffect(() => {
    updateBlance();
  }, [loading])


  const handleConfirm = async () => {
    showEncryptDialog(true, "INPUT_PASSWORD");
  };

  React.useEffect(() => {
    getGasPrice();
  }, []);

  return (
    <Box p="2" minH="100vh">
      {!contract ? (
        <TokenItem
          price=""
          balance={balance}
          symbol="ETH"
          name="Ethereum"
          icon={<FontAwesomeIcon size="2x" icon={brands("ethereum")} />}
        />
      ) : (
        <TokenItem
          price=""
          balance={erc20Token?.balance || ""}
          symbol={erc20Token?.symbol || ""}
          name={erc20Token?.name || ""}
          icon={<FontAwesomeIcon size="2x" icon={brands("ethereum")} />}
        />
      )}
      <VStack alignItems="stretch">
        <HStack>
          <Text>To:</Text>
          <Input onChange={handleToAddressChange} placeholder="Input address" />
        </HStack>
        <InputGroup>
          <Input type="number" onChange={handleAmountChange} placeholder="0" />
          <InputRightAddon children={erc20Token?.symbol || "ETH"} />
        </InputGroup>
        <HStack>
          {gasPriceLoading && <CircularProgress isIndeterminate size={5} />}
          <Text>GAS: {gasPrice} Gwei</Text>
        </HStack>
        <Button isLoading={loading} onClick={handleConfirm}>
          Confirm
        </Button>
      </VStack>
      <EncryptDiloag onConfirm={handleConfirmPassword} />
    </Box>
  );
}
