import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Box, Input, Text, useToast} from "@chakra-ui/react";
import WhiteSpace from "../../../components/WhiteSpace";
import {THEME} from "../../../config/theme";
import InputItem from "../../../components/InputItem";
import NavigationBar from "../../../components/NavigationBar";
import FloatBottomBtn from "../../../components/FloatBottomBtn";
import BottomModalWithBottom from "../../../components/BottomModalWithBottom";
import {deploy} from "../../../utils/etherUtils";
import {parseUnits} from "ethers/lib/utils";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/rootStore";
import useEncrypt from "../../../hooks/useEncrypt";
import {useCheckFundsMutation, usePlaceOrderMutation} from "../../../reducer/apiSlice";
import {OrderType} from "../components/OrderItem";
import {NEW_CONTRACT_PRICE, TRANSFER_PRICE} from "../../../config/system";
import {useAppDispatch} from "../../../hooks/store";
import {
  PendingContract,
  setAfterPayPath,
  setPendingContract,
  setShowToast,
  setTransfer
} from "../../../reducer/appSlice";
import {useNavigate} from "react-router-dom";
import numeral from 'numeral'

const NewToken = () => {

  const {
    transfer: contractData,
    currentWallet,
    network,
    afterPay
  } = useSelector((rootState: RootState) => rootState.app)
  const {decryptPrivatekey} = useEncrypt();
  const [placeOrder] = usePlaceOrderMutation()
  const [name, setName] = useState('')
  const [symbol, setSymbol] = useState('')
  const [value, setValue] = useState('')
  const [showPasswordInputModal, setShowPasswordInputModal] = useState(false)
  const [password, setPassword] = useState('')
  const toast = useToast()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  /**
   * 处理支付完成后，跳转至继续转账的流程
   * **/
  useEffect(() => {
    if (contractData && afterPay) {
      setName(contractData?.tokenName || '')
      setSymbol(contractData?.symbol || '')
      setValue(contractData?.value || '')
      setShowPasswordInputModal(true)
      dispatch(setAfterPayPath(undefined))
    }

  }, [contractData, afterPay])

  /**
   * 创建充值订单
   * **/
  const createOrder = async (value: number = 0, meta?: any) => {
    const params: any = {
      address: currentWallet?.address,
      value: Math.abs(value),
      type: value >= 0 ? OrderType.CONSUME : OrderType.TOP_UP,
      meta: meta
    }
    placeOrder(params)
  }

  const onNext = () => {
    dispatch(setAfterPayPath({
      route: '/my/new-token'
    }))
    dispatch(setTransfer({
      tokenName: name,
      symbol: symbol,
      value: value
    }))
    navigate('/payment', {
      state: {
        value: NEW_CONTRACT_PRICE,
        type: OrderType.CONSUME
      }
    })
  }

  const onSubmit = async () => {
    if (!currentWallet) {
      return;
    }
    if (!password) {
      toast({
        position: 'top',
        title: '请输入密码',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return;
    }
    try {
      const decryptKey = decryptPrivatekey(password, currentWallet?.privateKey || '')
      if (!decryptKey.startsWith('0x')) {
        toast({
          title: "密码错误",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
        return
      }
      dispatch(setShowToast('创建中'))
      const contract = await deploy(network, decryptKey, 'SampleERC20', [name, symbol, parseUnits(value)]);
      const pendingData: PendingContract = {
        address: contract.address,
        hash: contract.hash,
        name: name,
        symbol: symbol,
        timeStamp: `${Date.now() / 1000}`,
        value: value
      }
      dispatch(setPendingContract(pendingData))
      await createOrder(NEW_CONTRACT_PRICE, contract)
      navigate('/my/waiting', {
        replace: true
      })
    } catch (e) {
      console.log(e)
      toast({
        position: 'top',
        title: '发币失败',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      dispatch(setShowToast(false))
    }
  }
  return (
    <React.Fragment>
      <NavigationBar title='我要发币'/>
      <Container>
        <InputItem
          value={name}
          onChange={(e) => {
            setName(e.target.value)
          }}
          title='代币名称'
          placeholder='输入英文字符, 如CatToken'
        />

        <WhiteSpace/>
        <InputItem
          value={symbol}
          onChange={(e) => {
            setSymbol(e.target.value)
          }}
          title='代币缩写'
          placeholder='输入英文字符, 如CT'
        />
        <WhiteSpace/>
        <InputItem
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
          }}
          title='发行数量'
          placeholder='输入数字, 如10000000'
        />
        <WhiteSpace/>
        <div className='gas-title'>服务费</div>
        <Box className='gas-container'>
          <div className='gas-value'>
            <Text>NZ$ {numeral(NEW_CONTRACT_PRICE).divide(100).format('0,0.00')}</Text>
          </div>
        </Box>
        <WhiteSpace height={64}/>
      </Container>
      <FloatBottomBtn title="下一步" onClick={onNext}/>
      <BottomModalWithBottom
        modalTitle='密码'
        onClose={() => {
          setShowPasswordInputModal(false)
        }}
        open={showPasswordInputModal}
        onBtnClicked={onSubmit}
      >
        <ModalContainer>
          <WhiteSpace/>
          <Input
            value={password}
            onChange={e => {
              setPassword(e.target.value)
            }}
            type='password'
            placeholder='输入密码'
            variant='outline'
            focusBorderColor='none'
            _placeholder={{opacity: 1, color: THEME.color.gray["#2-045"]}}
            style={{backgroundColor: 'white', border: "none", height: 64}}
          />
          <WhiteSpace height={120}/>
        </ModalContainer>
      </BottomModalWithBottom>
    </React.Fragment>
  );
};

export default NewToken;

const Container = styled.div`
  padding: 22px;

  .gas-title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    font-feature-settings: 'case' on;
    color: ${THEME.color.gray["#2-045"]};
    margin-bottom: 8px;
  }

  .gas-container {
    background-color: white;
    padding: 16px;
    border-radius: 8px;

    .gas-sub-title {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      font-feature-settings: 'case' on;
      color: ${THEME.color.gray["#2-045"]}
    }

    .gas-value {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      font-feature-settings: 'case' on;
      color: ${THEME.color.gray["#2-045"]}
    }
  }
`;

const ModalContainer = styled.div`
  background-color: #F7F8F9;
`;
