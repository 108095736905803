import React from "react";
import styled from 'styled-components'
import {Button} from "@chakra-ui/react";
import {THEME} from "../config/theme";

type FloatBottomBtnPropsType = {
  title: string,
  disable?: boolean
  onClick?: () => void
  danger?: boolean
}

const FloatBottomBtn = (props: FloatBottomBtnPropsType) => {

  return (
    <Container>
      <Button
        variant={props.danger ? 'outline' : 'solid'}
        disabled={props.disable}
        style={{
          width: '100%',
          backgroundColor: props.danger ? "white" : THEME.color.primary.default,
          color: props.danger ? THEME.color.primary.danger : 'white',
          borderColor: props.danger ? THEME.color.primary.danger : 'white'
        }}
        onClick={() => {
          if (props.onClick) {
            props.onClick()
          }
        }}
      >
        {props.title}
      </Button>
    </Container>
  )
}

export default FloatBottomBtn

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  padding: 8px 24px;
  flex-direction: row;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  border-top: 0.5px solid rgba(60, 60, 67, 0.36);
  backdrop-filter: blur(10px);
`
