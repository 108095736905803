import { Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import { combineReducers } from 'redux'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import counterReducer from '../features/counter/counterSlice';
import {apiSlice} from "../reducer/apiSlice";
import appReducer from '../reducer/appSlice';
import encryptReducer from '../reducer/encryptSlice';
import { chainSlice } from '../reducer/chainSlice';

const rootReducer = combineReducers({
  app: appReducer,
  counter: counterReducer,
  encrypt: encryptReducer,
  [chainSlice.reducerPath]: chainSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer
})

const persistedReducer = persistReducer({
  key: 'root',
  version: 2,
  storage,
  blacklist: ['encrypt', 'counter', 'password', chainSlice.reducerPath, 'showToast', apiSlice.reducerPath, 'pendingTrans']
}, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(chainSlice.middleware, apiSlice.middleware),
  // middleware: [serializableMiddleware]
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
