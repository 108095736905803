import {ChevronRightIcon} from "@chakra-ui/icons";
import React from "react";
import styled from 'styled-components'
import {THEME} from "../config/theme";

type ListItemProps = {
  title: string,
  icon?: React.ReactElement | null,
  rightElement?: React.ReactElement | string | null,
  onClick: () => void
}

const ListItem = (props: ListItemProps) => {

  const renderRight = () => {
    if (!props.rightElement) {
      return null
    }
    if (typeof props.rightElement === 'string') {
      return props.rightElement
    }
    return props.rightElement
  }

  const renderIcon = () => {
    if (!props.icon) {
      return null
    }
    return props.icon
  }
  return (
    <Container onClick={()=>{
      if (props.onClick){
        props.onClick()
      }
    }}>
      {renderIcon()}
      <div className='left' style={{marginLeft: props.icon ? '8px' : '0px', flex: props.rightElement ? 'inherit': '1'}}>
        {props.title}
      </div>
      {props.rightElement && (
        <div className='right'>
          {renderRight()}
        </div>
      )}
      <ChevronRightIcon className='icon' w={8} h={8}/>
    </Container>
  )
}

export default ListItem

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #EAECF5;
  padding-bottom: 24px;

  .left {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    font-feature-settings: 'case' on;
    color: ${THEME.color.gray["#2-045"]};
    width: 64px;
    text-align: left;
  }

  .right {
    margin-left: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    font-feature-settings: 'case' on;
    color: ${THEME.color.gray["#4-085"]};
    flex: 1;
    word-wrap: anywhere;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .icon {
    width: 24px;
    text-align: center;
    margin-left: 4px;
    color: ${THEME.color.gray["#2-045"]};;
  }
`
