import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { merge } from "lodash";

export type EncryptDialogType = 'SET_PASSWORD_IMPORT' | 'INPUT_PASSWORD' | 'SET_PASSWORD_NEW';

export interface EncryptState {
    showDialog: boolean,
    password: string,
    type?: EncryptDialogType,
}

const initialState: EncryptState = { showDialog: false, password: '' };

export const encryptSlice = createSlice({
    name: 'encrypt',
    initialState,
    reducers: {
      updateEncryptState: (state, action: PayloadAction<Partial<EncryptState>>) => {
        state = merge(state,action.payload);
      }
    },
})

// Action creators are generated for each case reducer function
export const { updateEncryptState } = encryptSlice.actions

export default encryptSlice.reducer