import {Image} from "@chakra-ui/react";
import React from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import styled from "styled-components";
import FloatBottomBtn from "../components/FloatBottomBtn";
import NavigationBar from "../components/NavigationBar";
import {useAppDispatch, useAppSelector} from "../hooks/store";
import {setAfterPayPath} from "../reducer/appSlice";
import {RootState} from "../store/rootStore";
import success from '../svg/success.svg'

const PaymentSuccess = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {afterPay} = useAppSelector(
    (state: RootState) => state.app
  );
  const [searchParams] = useSearchParams();
  return (
    <React.Fragment>
      <NavigationBar title='支付成功' showBackIcon/>
      <Container>
        <Image
          className='image'
          boxSize="46px"
          src={success}
          alt="Icon"
        />
        <div className='message'>服务费充值成功</div>
      </Container>
      <FloatBottomBtn title='完成' onClick={() => {
        const redirectRoute = searchParams.get('redirect')
        if (afterPay) {
          navigate(afterPay.route, {
            replace: true
          })
        } else if (redirectRoute) {
          navigate(redirectRoute, {
            replace: true
          })
        } else {
          navigate('/my/topUpHistory', {
            replace: true
          })
        }

      }}/>
    </React.Fragment>
  )
}

export default PaymentSuccess


const Container = styled.div`
  padding: 180px 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .message {
    margin-top: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    font-feature-settings: 'case' on;
    color: #6AC6A1;
  }

`;
