import {
  AlertDialog, AlertDialogBody,
  AlertDialogContent, AlertDialogFooter, AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay, useDisclosure,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import ListItem from "../../components/ListItem";
import NavigationBar from "../../components/NavigationBar";
import WhiteSpace from "../../components/WhiteSpace";
import FloatBottomBtn from "../../components/FloatBottomBtn";
import {THEME} from "../../config/theme";
import {useAppDispatch, useAppSelector} from "../../hooks/store";
import {
  deleteWallet,
  updateWallet,
  WalletState
} from "../../reducer/appSlice";
import {RootState} from "../../store/rootStore";
import {DEFAULT_WALLET_ICON} from "./Wallet";

const WalletSetting = () => {
  const [walletName, setWalletName] = useState<string | undefined>('')
  const [showWalletNameModal, setShowWalletNameModal] = useState(false)
  const [targetWallet, setTargetWallet] = useState<WalletState>()

  const {isOpen, onOpen, onClose} = useDisclosure()
  const cancelRef: any = React.useRef()
  const {currentWallet, wallets} = useAppSelector(
    (state: RootState) => state.app
  );
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location && location.state && location.state.wallet) {
      setTargetWallet(location.state.wallet)
    } else {
      if (currentWallet) {
        setTargetWallet(currentWallet)
      }
    }
  }, [location])


  const onDelete = async () => {
    if (targetWallet) {
      dispatch(deleteWallet(targetWallet))
    }
    navigate(-1)
  }


  useEffect(() => {
    setWalletName(targetWallet?.name)
  }, [targetWallet?.name])

  return (
    <div>
      <NavigationBar title='管理'/>
      <Container>
        <WhiteSpace height={16}/>
        <ListItem
          title='钱包地址'
          rightElement={targetWallet?.address}
          onClick={() => {

          }}/>
        <WhiteSpace height={16}/>
        <ListItem
          title='钱包名称'
          rightElement={targetWallet?.name}
          onClick={() => {
            setShowWalletNameModal(true)
          }}/>
        <WhiteSpace height={16}/>
        <ListItem
          title='钱包头像'
          rightElement={<Image
            boxSize="24px"
            src={targetWallet?.icon || DEFAULT_WALLET_ICON}
            alt="Icon"
          />}
          onClick={() => {

          }}/>
        <WhiteSpace height={16}/>
        <ListItem
          title='备份钱包'
          onClick={() => {

          }}/>
      </Container>
      <FloatBottomBtn title='移除' onClick={onOpen} danger/>
      <Modal
        isCentered
        size='sm'
        closeOnOverlayClick={false}
        isOpen={showWalletNameModal}
        onClose={() => {
          setShowWalletNameModal(false)
        }}>
        <ModalOverlay/>
        <ModalContent style={{margin: 16}}>
          <ModalHeader>钱包名称</ModalHeader>
          <ModalCloseButton/>
          <ModalBody pb={6}>
            <Input value={walletName || ''} onChange={e => {
              setWalletName(e.target.value)
            }}/>
            <WhiteSpace height={24}/>
            <Button
              variant='solid'
              style={{
                width: '100%',
                backgroundColor: THEME.color.primary.default,
                color: 'white',
              }}
              onClick={() => {
                const newTarget = {
                  ...targetWallet,
                  name: walletName
                }
                setTargetWallet(newTarget)
                dispatch(updateWallet(newTarget))
                setShowWalletNameModal(false)
              }}
            >
              确认修改
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
      <AlertDialog
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}>
        <AlertDialogOverlay>
          <AlertDialogContent style={{margin: 24}}>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              删除钱包
            </AlertDialogHeader>
            <AlertDialogBody>
              是否确定删除当前钱包？
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                取消
              </Button>
              <Button colorScheme='red' onClick={onDelete} ml={3}>
                删除
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  )
}

export default WalletSetting

const Container = styled.div`
  padding: 16px;

`
