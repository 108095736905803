import {Checkbox, Image} from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";
import {THEME} from "../config/theme";
import master from '../svg/card/mastcard.svg'
import newCard from '../svg/card/newCard.svg'
import visa from '../svg/card/visa.svg'

type CardInfoType = {
  cardNo: string,
  cardType: string,
  cardToken: string,
}

type CardItemProps = {
  info: CardInfoType,
  isSelect: boolean,
  onClick: (card: any)=>void
}

const CardItem = (props: CardItemProps)=>{
  const getCardLogo = ()=>{
    switch (props.info.cardType) {
      // TODO fixed this
      case 'master':{
         return master
      }
      case 'visa':{
        return visa
      }
      default:{
        return newCard
      }
    }
  }
  return (
    <Container onClick={()=>{
      if (props.onClick){
        props.onClick(props.info.cardToken)
      }
    }}>
      <div className='icon'>
        <Image
          className='image'
          boxSize="24px"
          src={getCardLogo()}
          alt="Icon"
        />
      </div>
      <div className='card'>{props.info.cardNo}</div>
      <div className='check'>
        <Checkbox
          size="md"
          colorScheme="green"
          isChecked={props.isSelect}
        ></Checkbox>
      </div>
    </Container>
  )
}

export default CardItem

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding: 16px;
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  .icon{
    width: 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
  .card{
    flex: 1;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: ${THEME.color.gray["#4-085"]};
  }
  .check{
    width: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`
