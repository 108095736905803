import {Image} from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";
import WhiteSpace from "../../../components/WhiteSpace";
import {THEME} from "../../../config/theme";
import hint from "../../../svg/hint.svg";


const IntroduceGasFee = () => {
  return (
    <Container>
      <Image
        className='image'
        boxSize="40px"
        src={hint}
        alt="Icon"
      />
      <div className='title'>
        什么是服务费？
      </div>
      <WhiteSpace/>
      <div className='desc'>
        服务费是支付给矿工的手续费，当你在以太坊区块链上进行转账时，矿工要把你的交易打包并放上区块链，才能使交易完成，在这过程中会消耗区块链的运算资源，所以要支付费用。
      </div>
    </Container>
  )
}

export default IntroduceGasFee

const Container = styled.div`
  padding: 24px 24px 8px 24px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  .title{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: 'case' on;
    color: ${THEME.color.gray["#4-085"]};
  }
  .desc{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: 'case' on;
    color: ${THEME.color.gray["#2-045"]}
  }
`
