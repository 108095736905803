import React from "react";
import styled from "styled-components";
import {useAppSelector} from "../../../hooks/store";
import {RootState} from "../../../store/rootStore";
import income from "../../../svg/income.svg";
import failedTrans from "../../../svg/failed.svg";
import outcome from "../../../svg/export.svg";
import {Box, HStack, Text, Image, VStack, Progress, Stack} from "@chakra-ui/react";
import dayjs from "dayjs";
import {THEME} from "../../../config/theme";
import {formatAddressWithDot} from "../../../utils/Helpers";
import {ethers} from "ethers";
import {useNavigate} from "react-router-dom";

type TransactionItemProps = {
  info: {
    confirmations: string;
    contractAddress: string;
    from: string;
    tokenDecimal: string;
    timeStamp: string;
    to: string;
    value: string;
  };
  pending?: boolean
};

const TransactionItem = (props: TransactionItemProps) => {
  const {info} = props;
  const navigate = useNavigate()
  const {currentWallet} = useAppSelector(
    (rootState: RootState) => rootState.app
  );
  let type = "IN";
  if (currentWallet?.address?.toUpperCase() === info.from.toUpperCase()) {
    type = "OUT";
  } else if (currentWallet?.address?.toUpperCase() === info.to.toUpperCase()) {
    type = "IN";
  }
  if (!info.confirmations) {
    type = "FAILED";
  }
  return (
    <Container>
      <HStack width="100%" p={1} mt="8px">
        <Box
          borderRadius="full"
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          width="40px"
        >
          <img
            src={
              type === "IN" ? income : type === "OUT" ? outcome : failedTrans
            }
            alt="logo"
          />
        </Box>
        <VStack
          style={{
            borderBottom: "1px solid #EAECF5",
            flex: 1,
            alignItems: "flex-start",
            paddingBottom: 8,
          }}
          onClick={() => {
            navigate("/my/transaction-detail", {state: props.info});
          }}
        >
          <HStack
            alignItems="center"
            justify="space-between"
            style={{
              width: "100%",
              flex: 1,
              overflow: "hidden",
            }}
          >
            <Text className="address">
              {type === "IN"
                ? formatAddressWithDot(info.from)
                : formatAddressWithDot(info.to)}
            </Text>
            <Text
              className="value"
              style={{
                color: type === "OUT" ? "#CC1543" : THEME.color.gray["#4-085"],
              }}
            >
              {Number(info.value) > 0 ? ethers.utils.formatUnits(`${info.value}`, info.tokenDecimal || 18) : '0'}
            </Text>
          </HStack>
          <HStack
            alignItems="center"
            justify="space-between"
            style={{
              width: "100%",
              flex: 1,
              overflow: "hidden",
            }}
          >
            <Text className="info">
              {dayjs(Number(info.timeStamp) * 1000).format("DD/MM/YYYY HH:mm:ss")}
            </Text>
            {props.pending ? (
              <Text className="info">
                确认中
              </Text>
            ): null}
          </HStack>
          {props.pending ? (
            <Stack style={{
              width: "100%",
              flex: 1,
              overflow: "hidden",
            }}>
            <Progress colorScheme='green' size='sm' isIndeterminate />
            </Stack>
          ): null}
        </VStack>
      </HStack>
    </Container>
  );
};

export default TransactionItem;

const Container = styled.div`
  width: 100%;

  .address {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: ${THEME.color.gray["#4-085"]};
  }

  .info {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: ${THEME.color.gray["#2-045"]};
  }

  .value {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: ${THEME.color.gray["#4-085"]};
  }
`;
