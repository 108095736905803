import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {blockScoutAddress} from "../config/system";

interface ResponseType<T> {
  message: string,
  result: T,
  status: number
}

type TransactionsByAddressParamsType = {
  addressHash: string | undefined,
  contractAddress: string | undefined
}

interface IContractBalance {
  contract: string,
  address: string
}

export const chainSlice = createApi({
  reducerPath: 'chain',
  baseQuery: fetchBaseQuery({
    baseUrl: blockScoutAddress,
    prepareHeaders: (headers) => {
      return headers
    },
  }),
  keepUnusedDataFor: 3,
  tagTypes: ['Transactions'],
  endpoints: (builder) => {
    return {
      getTransactionInfo: builder.query<ResponseType<any>, string | undefined>({
        query: (transactionHash) => {
          return `?module=transaction&action=gettxinfo&txhash=${transactionHash}`
        }
      }),
      getBalanceByAddress: builder.query<ResponseType<any>, string | undefined>({
        query: (addressHash) => {
          return `?module=account&action=balance&address=${addressHash}`
        }
      }),
      getTokenDataByContractAddress: builder.query<ResponseType<any>, string | undefined>({
        query: (contract) => {
          return `?module=token&action=getToken&contractaddress=${contract}`
        }
      }),
      getContractBalance: builder.query<ResponseType<any>, IContractBalance>({
        query: (params) => {
          return `?module=account&action=tokenbalance&contractaddress=${params.contract}&address=${params.address}`
        }
      }),
      checkTokenByWallet: builder.query<ResponseType<any>, string | undefined>({
        query: (addressHash) => {
          return `?module=account&action=tokenlist&address=${addressHash}`
        }
      }),
      transactionsByAddress: builder.query<ResponseType<any>, TransactionsByAddressParamsType>({
        query: (params) => {
          return `?module=account&action=tokentx&address=${params.addressHash}&contractaddress=${params.contractAddress}`
        },
        providesTags: ['Transactions'],
      })
    }
  },
})

export const {
  useCheckTokenByWalletQuery,
  useTransactionsByAddressQuery,
  useGetBalanceByAddressQuery,
  useGetContractBalanceQuery,
  useGetTransactionInfoQuery
} = chainSlice




