import {
  Box,
  HStack, Progress, Stack,
  Text, VStack,
} from "@chakra-ui/react";
import * as React from "react";
import styled from "styled-components";
import { THEME } from "../../../config/theme";
import {formatBalance} from "../../../utils/Helpers";

export interface ITokenItemProps {
  icon: any;
  balance: string;
  name: string;
  symbol: string;
  price: string;
  onClick?: () => void;
}

export default function TokenItem({
  icon,
  balance,
  name,
  symbol,
  price,
  onClick,
}: ITokenItemProps) {
  return (
    <Container>
      <VStack>
        <HStack width="100%" onClick={onClick} p={1}>
          <Box
            bg="#EFF1F8"
            borderRadius="full"
            color="#5F6ABA"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="40px"
            height="40px"
          >
            {icon}
          </Box>
          <HStack
            h="50px"
            alignItems="center"
            justify="space-between"
            style={{ borderBottom: "1px solid #EAECF5", flex: 1 }}
          >
            <Text className="info">{name}</Text>
            <Text className="info">{formatBalance(balance) || "0.0"}</Text>
          </HStack>
        </HStack>
      </VStack>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  .info {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    font-feature-settings: "case" on;
    color: ${THEME.color.gray["#4-085"]};
  }
`;
