import { mnemonicToSeed } from "bip39";
import { hdkey } from "ethereumjs-wallet";
import { toChecksumAddress, isValidAddress } from "ethereumjs-util";
import WalletTypes, { EthereumWalletType } from "../helpers/walletTypes";
import { startsWith } from "lodash";
import { Wallet } from "@ethersproject/wallet";
import { isHexString as isEthersHexString } from '@ethersproject/bytes';
import { isValidMnemonic as ethersIsValidMnemonic } from '@ethersproject/hdnode';



export enum WalletLibraryType {
  ethers = "ethers",
  bip39 = "bip39",
}

export type EthereumAddress = string;

export type EthereumPrivateKey = string;
type EthereumMnemonic = string;
type EthereumSeed = string;
export type EthereumWalletSeed =
  | EthereumAddress
  | EthereumPrivateKey
  | EthereumMnemonic
  | EthereumSeed;

export const DEFAULT_HD_PATH = `m/44'/60'/0'/0`;

export const deriveAccountFromMnemonic = async (
  mnemonic: string,
  index = 0
) => {
  let seed;
  seed = await mnemonicToSeed(mnemonic);
  const hdWallet = hdkey.fromMasterSeed(seed);
  const root = hdWallet.derivePath(DEFAULT_HD_PATH);
  const child = root.deriveChild(index);
  const wallet = child.getWallet();
  return {
    address: toChecksumAddress("0x" + wallet.getAddress().toString("hex")),
    isHDWallet: true,
    root,
    type: WalletTypes.mnemonic,
    wallet,
    walletType: WalletLibraryType.bip39,
  };
};

export const deriveAccountFromWalletInput = (input: EthereumWalletSeed) => {
    const type = identifyWalletType(input);
    if (type === WalletTypes.privateKey) {
      return deriveAccountFromPrivateKey(input);
    } else if (type === WalletTypes.readOnly) {
      const ethersWallet = { address: addHexPrefix(input), privateKey: null };
      return {
        address: addHexPrefix(input),
        isHDWallet: false,
        root: null,
        type: WalletTypes.readOnly,
        wallet: ethersWallet,
        walletType: WalletLibraryType.ethers,
      };
    }
    return deriveAccountFromMnemonic(input);
  };

export const deriveAccountFromPrivateKey = (privateKey: EthereumPrivateKey) => {
  const ethersWallet = new Wallet(addHexPrefix(privateKey));
  return {
    address: ethersWallet.address,
    isHDWallet: false,
    root: null,
    type: WalletTypes.privateKey,
    wallet: ethersWallet,
    walletType: WalletLibraryType.ethers,
  };
};

export const addHexPrefix = (value: string): string =>
  startsWith(value, "0x") ? value : `0x${value}`;

/**
 * @desc check if hex string
 * @param value The string to check
 * @return Whether or not the string was a hex string.
 */
 export const isHexString = (value: string): boolean => isEthersHexString(value);

/**
 * @desc Checks if a hex string, ignoring prefixes and suffixes.
 * @param value The string.
 * @return Whether or not the string is a hex string.
 */
 export const isHexStringIgnorePrefix = (value: string): boolean => {
    if (!value) return false;
    const trimmedValue = value.trim();
    const updatedValue = addHexPrefix(trimmedValue);
    return isHexString(updatedValue);
  };

/**
 * @desc is valid mnemonic
 * @param value The string to check.
 * @return Whether or not the string was a valid mnemonic.
 */
 export const isValidMnemonic = (value: string): boolean =>
 ethersIsValidMnemonic(value);

export const identifyWalletType = (
  walletSeed: EthereumWalletSeed
): EthereumWalletType => {
  if (
    isHexStringIgnorePrefix(walletSeed) &&
    addHexPrefix(walletSeed).length === 66
  ) {
    return EthereumWalletType.privateKey;
  }
  // 12 or 24 words seed phrase
  if (isValidMnemonic(walletSeed)) {
    return EthereumWalletType.mnemonic;
  }
  // Public address (0x)
  if (isValidAddress(walletSeed)) {
    return EthereumWalletType.readOnly;
  }
  // seed
  return EthereumWalletType.seed;
};