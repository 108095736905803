import {Image} from "@chakra-ui/react";
import React from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import wallet from '../svg/bottomTab/wallet.svg'
import wallet_selected from '../svg/bottomTab/wallet_selected.svg'
import transfer from '../svg/bottomTab/transfer.svg'
import transfer_selected from '../svg/bottomTab/transfer_selected.svg'
import me from '../svg/bottomTab/me.svg'
import me_selected from '../svg/bottomTab/me_selected.svg'

type BottomTabBarProps = {
  current?: 'WALLET' | 'TRANSFER' | 'ME',
}

const BottomTabBar = (props: BottomTabBarProps) => {
  const navigate = useNavigate()
  const onClick = (item: typeof props.current)=>{
    if (item === props.current){
      return
    }
    switch (item) {
      case 'WALLET':{
        navigate('/my/wallet')
        break;
      }
      case 'ME':{
        navigate('/my/myPage')
        break;
      }
      case "TRANSFER":{
        navigate("/my/select-token");
        break
      }
    }
  }

  return (
    <Container>
      <div className='item' onClick={()=>{onClick("WALLET")}} data-cy='item'>
        <div className='icon'>
          <Image
            className='image'
            boxSize="20px"
            src={props.current === 'WALLET' ? wallet_selected : wallet}
            alt="Icon"
          />
        </div>
        <div className='title' style={{color: props.current === 'WALLET' ? '#D975BB' : 'white'}}>钱包</div>
      </div>
      <div className='item'  onClick={()=>{onClick("TRANSFER")}}  data-cy='item'>
        <div className='icon'>
          <Image
            className='image'
            boxSize="20px"
            src={props.current === 'TRANSFER' ? transfer_selected : transfer}
            alt="Icon"
          />
        </div>
        <div className='title'  style={{color: props.current === 'TRANSFER' ? '#D975BB' : 'white'}}>转账</div>
      </div>
      <div className='item' onClick={()=>{onClick("ME")}} data-cy='item'>
        <div className='icon'>
          <Image
            className='image'
            boxSize="20px"
            src={props.current === 'ME' ? me_selected : me}
            alt="Icon"
          />
        </div>
        <div className='title'  style={{color: props.current === 'ME' ? '#D975BB' : 'white'}}>我的</div>
      </div>
    </Container>
  )
}

export default BottomTabBar

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 48px;
  z-index: 999;
  display: flex;
  padding: 8px 24px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #131B63 -18.9%, #481162 119.73%);
  box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0.3);
  border-top: 0.5px solid rgba(60, 60, 67, 0.36);
  backdrop-filter: blur(10px);

  .item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon {
      opacity: 0.6;
    }

    .title {
      font-size: 12px;
      color: white;
      opacity: 0.6;
    }
  }
`
