import {Image, useDisclosure} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import BottomModalWithBottom from "../../components/BottomModalWithBottom";
import FloatBottomBtn from "../../components/FloatBottomBtn";
import NavigationBar from "../../components/NavigationBar";
import {THEME} from "../../config/theme";
import {useAppSelector} from "../../hooks/store";
import {useLazyGetOrderHistoryQuery} from "../../reducer/apiSlice";
import {RootState} from "../../store/rootStore";
import numeral from 'numeral'
import wallet from "../../svg/question-mark.svg";
import IntroduceGasFee from "./components/IntroduceGasFee";
import OrderItem, {OrderStatus, OrderType} from "./components/OrderItem";

const TopUpHistory = () => {
  const {currentWallet, wallets} = useAppSelector(
    (state: RootState) => state.app
  );
  const [balance, setBalance] = useState(0);
  const [list ,setList] = useState<any[]>([])
  const [getHistory, {data}] = useLazyGetOrderHistoryQuery()
  const {isOpen, onOpen, onClose} = useDisclosure();
  const navigate = useNavigate()
  useEffect(() => {
    if (currentWallet?.address) {
      getHistory(currentWallet?.address)
    }
  }, [currentWallet?.address])

  useEffect(()=>{
    if (data && data.data){
      setBalance(data.data.balance)
      setList(data.data.history)
    }
  }, [data])
  return (
    <React.Fragment>
      <Container>
        <NavigationBar title='服务费充值' showBackIcon/>
        <div className='card'>
          <div className='question-mark' onClick={()=>{
            onOpen()
          }}>
            <Image
              className='image'
              boxSize="16px"
              src={wallet}
              alt="Icon"
            />
          </div>
          <div className='item' style={{fontWeight: 500}}>
            {numeral(balance).divide(100).format('$),0.00')}
          </div>
          <div className='item'>
            余额
          </div>
        </div>
        {(list && list.length > 0) ? (
          <div>
            {list.map(item=>{
              return (<OrderItem key={item.id} order={item}/>)
            })}
          </div>
        ) : (
          <div className='empty'>
            您还没有充值记录
          </div>
        )}
      </Container>
      <BottomModalWithBottom
        btnTitle='我知道了'
        open={isOpen}
        onBtnClicked={onClose}
        onClose={onClose}>
        <IntroduceGasFee/>
      </BottomModalWithBottom>
      <FloatBottomBtn title='充值' onClick={()=>{
        navigate('/my/topup')
      }}/>
    </React.Fragment>
  )
}

export default TopUpHistory

const Container = styled.div`
  padding: 24px;

  .card {
    padding:16px;
    color: white;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #131B63 -18.9%, #481162 119.73%);
    border-radius: 8px;
    .question-mark{
      display: flex;
      justify-content: flex-end;
      margin-bottom: -8px;
      z-index: 10;
    }
    .item{
      width: 100%;
      flex: 1;
      text-align: center;
    }
  }
  
  .empty{
    margin-top: 100px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    font-feature-settings: 'case' on;
    color: ${THEME.color.gray["#2-045"]};
  }
  
`;
