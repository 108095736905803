import React, {useEffect, useState} from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import WhiteSpace from "../../../components/WhiteSpace";
import {useAppSelector} from "../../../hooks/store";
import {WalletState} from "../../../reducer/appSlice";
import {RootState} from "../../../store/rootStore";
import HomePageBtn from "../../homepage/components/Button";
import WalletItem from "./WalletItem";

type WalletsListModalProps = {
  btnTitle?: string,
  modalTitle?: string,
  open: boolean
  autoSelect?: boolean
  onSelect: (wallet: WalletState) => void
  onOK: () => void
  onClose:()=>void
}


const WalletsListModal = (props: WalletsListModalProps) => {
  const {currentWallet, wallets} = useAppSelector(
    (state: RootState) => state.app
  );
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [selectWallet, setSelectWallet] = useState<WalletState>()

  useEffect(() => {
    if (props.open) {
      if ((wallets.length > 1 || !props.autoSelect)) {
        setOpen(true)
        if (currentWallet) {
          setSelectWallet(currentWallet)
        }
      } else {
        if (props.onSelect && currentWallet && props.autoSelect) {
          props.onSelect(currentWallet)
          if (props.onClose) {
            props.onClose()
          }
        }
        setOpen(false)
      }
    }

  }, [props.open])

  useEffect(() => {
    if (selectWallet) {
      if (props.onSelect) {
        props.onSelect(selectWallet)
      }
    }
  }, [selectWallet])

  return (
    <Modal
      motionPreset='slideInBottom'
      isOpen={open} onClose={() => {
      setOpen(false)
      if (props.onClose) {
        props.onClose()
      }
    }}>
      <ModalOverlay/>
      <ModalContent
        style={{
          position: "absolute",
          bottom: 0,
          top: 0,
          marginTop: 0,
          width: "100%",
          maxWidth: "768px",
          marginBottom: 0,
          backgroundColor: '#F7F8F9'
        }}
      >

        <ModalHeader style={{
          display: "flex",
          flexDirection: 'row',
          alignItems: "center"
        }}>
          <ModalCloseButton
            style={{right: "inherit", left: "var(--chakra-space-3)", top: 18}}
          />
          <span style={{flex: 1, textAlign: "center"}}>
          选择钱包
          </span>
          <div
            style={{fontSize: 18, color: '#D975BB'}}
            onClick={() => {
              if (props.onSelect && selectWallet) {
                props.onSelect(selectWallet)
              }
              setOpen(false)
              if (props.onOK) {
                props.onOK()
              }
            }}
          >
            完成
          </div>
        </ModalHeader>
        <ModalBody>
          {wallets.map(item => {
            return (
              <WalletItem
                key={item.address}
                isSelected={selectWallet?.address === item.address}
                onChecked={() => {
                  setSelectWallet(item)
                }}
                walletInfo={item}
              />
            )
          })}
          <FloatContainer>
            <HomePageBtn title='创建身份' subTitle='使用新的钱包' onClick={() => {
              navigate("/new-wallet-step1");
            }}/>
            <WhiteSpace/>
            <HomePageBtn title='恢复身份' subTitle='使用我已拥有的钱包' onClick={() => {
              navigate('/restore-wallet')
            }}/>
            <WhiteSpace height={32}/>
          </FloatContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default WalletsListModal;

const FloatContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  padding: 32px 22px;
  flex-direction: column;
  align-items: center;
`
