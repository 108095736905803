import { ColorModeScript } from "@chakra-ui/react"
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { App } from "./App"
import {__dev__} from "./config/system";
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import { store } from './store/rootStore'
import { Provider } from 'react-redux'
import {
  ChakraProvider
} from "@chakra-ui/react"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import theme from "./theme";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const container = document.getElementById("root")

if (!container) throw new Error('Failed to find the root element');

const root = ReactDOM.createRoot(container)

let persistor = persistStore(store)

if (!__dev__){
  Sentry.init({
    dsn: "https://0a13d7b2841f458db6442be5f2205f1f@o89659.ingest.sentry.io/4504154272759808",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}



root.render(
  <React.Fragment>
    <ColorModeScript />
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ChakraProvider>
  </React.Fragment>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

