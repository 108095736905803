import React, {useEffect, useState} from "react";
import styled from 'styled-components'
import FloatBottomBtn from "../../components/FloatBottomBtn";
import NavigationBar from "../../components/NavigationBar";
import {Image, VStack, Text, Box} from "@chakra-ui/react";
import {THEME} from "../../config/theme";
import WhiteSpace from "../../components/WhiteSpace";
import {useNavigate} from "react-router-dom";

const NewWalletStep2 = ()=>{
  const navigate = useNavigate()
  return (
    <div>
      <NavigationBar title='备份助记词'/>
      <HintContainer>
        <VStack spacing={4}>
          <Image mt={8} ml={16} mr={16}
                 boxSize='160px'
                 src='https://res.cloudinary.com/shenmanz/image/upload/v1664843331/web3_lab/Frame.png'
                 alt='Icon'/>
          <Text style={{fontSize: 18, fontWeight: 500, color: THEME.color.gray["#4-085"]}}>备份助记词，保障钱包安全</Text>
          <Text style={{fontSize: 14, fontWeight: 400, color: THEME.color.gray["#2-045"], textAlign: "center"}}>当更换手机或重装应用时，你将需要助记词 (12
            个英文单词）恢复钱包。为保障钱包安全，请务必尽快完成助记词备份。</Text>
          <Box style={{backgroundColor: THEME.color.primary.variant2, textAlign: "center", padding: 16, borderRadius: 8}}>
            <Text style={{color: '#A03E82', fontSize: 16, fontWeight: 500}}>重要提醒</Text>
            <WhiteSpace height={8}/>
            <Text style={{color: '#F7F8F9', fontSize: 14, fontWeight: 400}}>获得记词等于拥有钱包资产所有权。</Text>
            <WhiteSpace height={8}/>
            <Text style={{color: '#A03E82', fontSize: 16, fontWeight: 500}}>如何安全地备份助记词？</Text>
            <WhiteSpace height={8}/>
            <Text style={{color: '#F7F8F9', fontSize: 14, fontWeight: 400}}>使用纸笔，按正确次序抄写助记词
              将助记词保管至安全的地方</Text>
          </Box>
        </VStack>
      </HintContainer>
      <FloatBottomBtn title='立即备份' onClick={()=>{
        navigate('/new-wallet-step3')
      }}/>
    </div>
  )
}

const HintContainer = styled.div`
  padding: 16px;
`

export default NewWalletStep2
