import React, {useEffect, useMemo, useState} from "react";
import styled from 'styled-components'
import FloatBottomBtn from "../../components/FloatBottomBtn";
import NavigationBar from "../../components/NavigationBar";

import {useAppSelector} from "../../hooks/store";
import {RootState} from "../../store/rootStore";
import {THEME} from "../../config/theme";
import {Box, Tag} from "@chakra-ui/react";
import WhiteSpace from "../../components/WhiteSpace";
import {isEqual, shuffle} from "lodash";
import {deriveAccountFromMnemonic} from "../../utils/ethereumUtil";
import useEncrypt from "../../hooks/useEncrypt";
import {useNavigate} from "react-router-dom";

const NewWalletStep4 = () => {
  const {newChive} = useAppSelector((state: RootState) => state.app);
  const {addWalletEncrypt} = useEncrypt();
  const navigate = useNavigate()

  const reSortedSeeds = useMemo(() => {
    // 由于创建钱包的种子有可能重复，因此添加index作为唯一索引记录。
    return shuffle(newChive.seeds.split(' ').map((item, index)=>`${index}_${item}`))
  }, [newChive.seeds])
  const [selectedSeeds, setSelectedSeeds] = useState<string[]>([])
  const [selectedSeedsSet, setSelectedSeedsSet] = useState(new Set())
  const [canNext, setCanNext] = useState(false)

  useEffect(() => {
    setSelectedSeedsSet(new Set(selectedSeeds))
    const reformatedSeeds = selectedSeeds.map(item=>item.split('_')[1])
    if (isEqual(reformatedSeeds, newChive.seeds.split(' '))) {
      setCanNext(true)
    }
  }, [selectedSeeds])

  const onSubmit = async () => {
    const {wallet} = await deriveAccountFromMnemonic(newChive.seeds);
    const privateKey = wallet.getPrivateKeyString();
    addWalletEncrypt(wallet.getAddressString(), privateKey);
    navigate('/my/wallet')
  }

  return (
    <div>
      <NavigationBar title='备份助记词'/>
      <Container>
        <div className='page-title'>
          确认助记词
        </div>
        <div className='page-desc'>
          请按顺序点击助记词，以确认您正确备份
        </div>
        <WhiteSpace/>
        <Box className='display' minH='168px'>
          {selectedSeeds.map((item, index) => {
            return (
              <Tag
                onClick={() => {
                  selectedSeedsSet.delete(item)
                  setSelectedSeeds(selectedSeeds.filter(_item => _item !== item))
                }}
                className='item'
                size='lg'
                key={`${item}_${index}`}
                variant='solid'>
                {item.split('_')[1]}
              </Tag>
            )
          })}
        </Box>
        <WhiteSpace/>
        <Box className='select-area'>
          {reSortedSeeds.map((item, index) => {
            return (
              <Tag
                onClick={() => {
                  if (selectedSeedsSet.has(item)) {
                    return
                  }
                  setSelectedSeeds([...selectedSeeds, item])
                }}
                className='item'
                size='lg'
                style={{
                  backgroundColor: selectedSeedsSet.has(item) ? '#F7F8F9' : 'white',
                  color: selectedSeedsSet.has(item) ? THEME.color.gray["#1-025"] : THEME.color.gray["#4-085"]
                }}
                key={`${item}_${index}`}
                variant='solid'>
                {item.split('_')[1]}
              </Tag>
            )
          })}
        </Box>
      </Container>
      <FloatBottomBtn title='下一步' disable={!canNext} onClick={() => {
        onSubmit()
      }}/>
    </div>
  )
}

const Container = styled.div`
  padding: 16px;

  .page-title {
    color: ${THEME.color.gray["#4-085"]};
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    font-feature-settings: 'case' on;
  }

  .page-desc {
    color: ${THEME.color.gray["#2-045"]};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    font-feature-settings: 'case' on;
    margin-top: 8px;
  }

  .display {
    background: white;
    border-radius: 8px;
    padding: 16px;

    .item {
      margin: 4px;
      background-color: #F7F8F9;
      padding: 8px;
      border: 1px solid #EAECF5;
      border-radius: 8px;
      color: ${THEME.color.gray["#4-085"]};
    }
  }

  .select-area {
    background: none;
    border-radius: 8px;

    .item {
      margin: 4px;
      background-color: white;
      padding: 8px;
      border: 1px solid #EAECF5;
      border-radius: 8px;
      color: ${THEME.color.gray["#4-085"]};
    }
  }

`

export default NewWalletStep4
