import {Box, CircularProgress, Divider, HStack, Input, List, ListItem, Text, useToast} from "@chakra-ui/react";
import numeral from 'numeral'
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import BottomModalWithBottom from '../../../components/BottomModalWithBottom'
import FloatBottomBtn from "../../../components/FloatBottomBtn";
import InputItem from "../../../components/InputItem";
import NavigationBar from "../../../components/NavigationBar";
import WhiteSpace from "../../../components/WhiteSpace";
import {TRANSFER_PRICE} from "../../../config/system";
import {THEME} from "../../../config/theme";
import {useAppDispatch, useAppSelector} from "../../../hooks/store";
import useEncrypt from "../../../hooks/useEncrypt";
import useTransfer from "../../../hooks/useTransfer";
import {usePlaceOrderMutation} from "../../../reducer/apiSlice";
import {setAfterPayPath, setPendingTrans, setShowToast, setTransfer} from "../../../reducer/appSlice";
import {RootState} from "../../../store/rootStore";
import hint1 from "../../../svg/hint1.svg"
import hint2 from "../../../svg/hint2.svg"
import hint3 from "../../../svg/hint3.svg"
import {erc20Transfer} from "../../../utils/etherUtils";
import {formatBalance} from "../../../utils/Helpers";
import {OrderType} from "../components/OrderItem";

enum STEP_PROCESS {
  STEP1, STEP2, STEP3, STEP4, INIT
}

const Transfer = () => {
  const {transfer: transferData, currentWallet, network, afterPay} = useAppSelector(
    (state: RootState) => state.app
  );
  const [canNext, setCanNext] = useState(false)
  const {transfer} = useTransfer();
  const [placeOrder, {data}] = usePlaceOrderMutation()
  const [step, setStep] = useState<STEP_PROCESS>(STEP_PROCESS.INIT)
  const toast = useToast()
  const [to, setTo] = useState('')
  const [value, setValue] = useState('')
  const [note, setNote] = useState('')
  const dispatch = useAppDispatch()
  const [password, setPassword] = useState('')
  const {showEncryptDialog, decryptPrivatekey} = useEncrypt();
  const navigate = useNavigate()

  const onProcessStart = async () => {
    if (!to) {
      toast({
        position: 'top',
        title: '请设置收款地址',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return;
    }
    if (!value) {
      toast({
        position: 'top',
        title: '请设置转账数量',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return;
    }
    dispatch(setTransfer({
      to: to,
      value: value,
      note: note
    }))
    setStep(STEP_PROCESS.STEP1)
  }

  /**
   * 处理支付完成后，跳转至继续转账的流程
   * **/
  useEffect(() => {
    if (transferData && afterPay) {
      setStep(STEP_PROCESS.STEP2)
      setTo(transferData?.to || '')
      setValue(transferData?.value || '')
      setNote(transferData?.note || '')
      dispatch(setAfterPayPath(undefined))
    }

  }, [transferData, afterPay])

  useEffect(() => {
    if (to && value && Number(value)<Number(transferData?.balance)) {
      setCanNext(true)
    } else {
      setCanNext(false)
    }
  }, [to, value])

  /**
   * 开始转账操作
   * **/
  const startTransaction = async () => {
    if (!transferData) {
      return
    }
    dispatch(setShowToast('发送中'))
    if (transferData.type === 'ETH') {
      const response = await transfer(
        transferData.to || '',
        transferData.value || '',
        decryptPrivatekey(password, currentWallet?.privateKey || "")
      );
    } else {
      if (currentWallet) {
        try {
          const decryptKey = decryptPrivatekey(password, currentWallet?.privateKey || '')
          if (!decryptKey.startsWith('0x')){
            toast({
              title: "密码错误",
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top",
            });
            return
          }
          const res = await erc20Transfer(
            transferData.contractAddress || '',
            network,
            decryptKey,
            transferData.to || '',
            transferData.value || ''
          );
          dispatch(setPendingTrans({
            ...res,
            value: Number(transferData.value) * (10 ** 18)
          }))
          createOrder(TRANSFER_PRICE, res)
        }catch (e) {
          console.log(e)
          toast({
            title: "转账失败",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
          return
        }finally{
          dispatch(setShowToast(false))
        }
      }
    }

    toast({
      title: "转账完成",
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
    navigate('/my/transactions', {
      replace: true
    })
  }

  /**
   * 创建消费订单
   * **/
  const createOrder = async (value: number = 0, meta?: any)=>{
    const params: any = {
      address: currentWallet?.address,
      value: Math.abs(value),
      type: value >= 0 ? OrderType.CONSUME : OrderType.TOP_UP,
      meta: meta
    }
    placeOrder(params)
  }


  return (
    <React.Fragment>
      <NavigationBar title={`${transferData?.tokenName}转账`}/>
      <Container>
        <InputItem
          required
          value={to}
          onChange={(e) => {
            setTo(e.target.value)
          }}
          title='收款地址'
          placeholder='输入收款地址'
        />

        <WhiteSpace/>
        <InputItem
          required
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
          }}
          title={`数量(${transferData?.symbol || ''})`}
          placeholder='输入转账数量'
          titleright={(<span>{formatBalance(transferData?.balance)}{transferData?.symbol}</span>)}
        />
        <WhiteSpace/>
        <InputItem
          value={note}
          onChange={(e) => {
            setNote(e.target.value)
          }}
          title='备注'
          placeholder='输入备注信息'
        />
        <WhiteSpace/>
        <div className='gas-title'>服务费</div>
        <Box className='gas-container'>
          <div className='gas-value'>
            <Text>NZ{numeral(TRANSFER_PRICE).divide(100).format('$ 0,0.00')}</Text>
            <Text>标准</Text>
          </div>
        </Box>
        <WhiteSpace height={64}/>
      </Container>
      <FloatBottomBtn title="下一步" onClick={onProcessStart} disable={!canNext}/>
      <BottomModalWithBottom
        onBtnClicked={async () => {
          switch (step){
            case STEP_PROCESS.STEP1:{
              dispatch(setAfterPayPath({
                route: '/my/transfer'
              }))
              navigate('/payment', {
                state:{
                  value: TRANSFER_PRICE,
                  type: OrderType.CONSUME
                },
                replace: true
              })
              break;
            }
            case STEP_PROCESS.STEP3:{
              // 发起转账
              await startTransaction()
              break;
            }
            default:{
              setStep(step => step + 1)
            }
          }
        }}
        onClose={() => {
          setStep(STEP_PROCESS.INIT)
        }}
        open={step !== STEP_PROCESS.INIT}
      >
        <ModalContainer>
          {step === STEP_PROCESS.STEP1 && <WarningMessage/>}
          {step === STEP_PROCESS.STEP2 && <TransferInfo info={transferData}/>}
          {step === STEP_PROCESS.STEP3 && <PasswordInput onChange={(value) => {
            setPassword(value)
          }}/>}
        </ModalContainer>
      </BottomModalWithBottom>
    </React.Fragment>
  );
};

export default Transfer;

const PasswordInput = (props: { onChange: (value: string) => void }) => {
  return (
    <PasswordContainer>
      <Text className="title">密码</Text>
      <WhiteSpace/>
      <Input
        onChange={e => {
          props.onChange(e.target.value)
        }}
        type='password'
        placeholder='输入密码'
        variant='outline'
        focusBorderColor='none'
        _placeholder={{opacity: 1, color: THEME.color.gray["#2-045"]}}
        style={{backgroundColor: 'white', border: "none", height: 64}}
      />
    </PasswordContainer>
  )
}
const PasswordContainer = styled.div`
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    font-feature-settings: 'case' on;
    text-align: center;
  }
`

const TransferInfo = (props: { info: any }) => {
  const {info} = props
  return (
    <TransferInfoContainer>
      <Text className="title">支付详情</Text>
      <WhiteSpace/>
      <div className='value'>{info.value} {info.symbol}</div>
      <WhiteSpace/>
      <List spacing={3}>
        <ListItem className="item">
          <HStack>
            <Text className='left'>支付信息</Text>
            <Text className='right'>{info.note}</Text>
          </HStack>
        </ListItem>
        <Divider/>
        <ListItem className="item">
          <HStack>
            <Text className='left'>收款地址</Text>
            <Text className='right'>{info.to}</Text>
          </HStack>
        </ListItem>
        <Divider/>
        <ListItem className="item">
          <HStack>
            <Text className='left'>付款地址</Text>
            <Text className='right'>{info.from}</Text>
          </HStack>
        </ListItem>
        <Divider/>
        <ListItem className="item">
          <HStack>
            <Text className='left'>服务费</Text>
            <Text className='right'>{numeral(TRANSFER_PRICE).divide(100).format('$0,0.00')}</Text>
          </HStack>
        </ListItem>
      </List>
    </TransferInfoContainer>
  )
}

const TransferInfoContainer = styled.div`
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    font-feature-settings: 'case' on;
    text-align: center;
  }

  .value {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    font-feature-settings: 'case' on;
    color: ${THEME.color.gray["#4-085"]};
  }

  .item {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
    padding-top: 16px;
    padding-bottom: 16px;

    .left {
      width: 100px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      font-feature-settings: 'case' on;
      color: ${THEME.color.gray["#2-045"]};
      text-align: center;
    }

    .right {
      flex: 1;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      font-feature-settings: 'case' on;
      color: ${THEME.color.gray["#4-085"]};
      text-align: left;
      align-items: flex-start;
      word-wrap: anywhere;
      justify-content: flex-start;
    }
  }
`

const WarningMessage = () => {
  return (
    <WarningMessageContainer>
      <WhiteSpace/>
      <Text className="title">风险提示</Text>
      <WhiteSpace/>
      <List spacing={3}>
        <ListItem className="item">
          <img className='icon' src={hint1} alt=""/>
          <span className='message'>请务必在转账操作前，仔细核对转账地址信息；</span>
        </ListItem>
        <ListItem className="item">
          <img className='icon' src={hint2} alt=""/>
          <span
            className='message'>你的转账行为一旦完成，对应的资产所有权将由变更为目标地址所对应的账户所有人享有；</span>
        </ListItem>
        <ListItem className="item">
          <img className='icon' src={hint3} alt=""/>
          <span
            className='message'>确保转账属于自愿行为，并确认不涉及任何传销、非法集资、诈骗等违法情形。谨防上当受骗，避免造成不必要的财产损失。</span>
        </ListItem>
      </List>
    </WarningMessageContainer>
  )
}

const ModalContainer = styled.div`
  height: 400px;
`;

const WarningMessageContainer = styled.div`
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    font-feature-settings: 'case' on;
  }

  .item {
    display: flex;
    flex-direction: row;

    .icon {
      width: 21px;
      height: 21px;
      margin-right: 22px;
    }
  }

  .message {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 1px;
    font-feature-settings: 'case' on;
    color: ${THEME.color.gray["#3-065"]}
  }
`


const Container = styled.div`
  padding: 22px;

  .gas-title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    font-feature-settings: 'case' on;
    color: ${THEME.color.gray["#2-045"]};
    margin-bottom: 8px;
  }

  .gas-container {
    background-color: white;
    padding: 16px;
    border-radius: 8px;

    .gas-sub-title {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      font-feature-settings: 'case' on;
      color: ${THEME.color.gray["#2-045"]}
    }

    .gas-value {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      font-feature-settings: 'case' on;
      color: ${THEME.color.gray["#2-045"]};
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

