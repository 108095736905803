import * as React from 'react';
import { useAppSelector, useAppDispatch } from './store';
import { RootState } from '../store/rootStore';
import { updateEncryptState, EncryptDialogType } from '../reducer/encryptSlice';
import { addWallet } from '../reducer/appSlice';
import { encrypt, decrypt } from '../utils/cryptoUtils';

const useEncrypt = () => {
    const encryptState = useAppSelector((rootState: RootState) => rootState.encrypt);
    const newChive = useAppSelector((rootState: RootState) => rootState.app.newChive);
    const dispatch = useAppDispatch();

    const showEncryptDialog = (showDialog: boolean, type?: EncryptDialogType) => {
        dispatch(updateEncryptState({showDialog, type}))
    }

    const addWalletEncrypt = (address: string, privateKey: string, password?: string) => {
        const privateKeyEncrypted = encrypt(newChive.password || password || '', privateKey);
        dispatch(addWallet({address, privateKey: privateKeyEncrypted, name: newChive.name || ''}));
    }

    const updatePassword = (password: string) => {
        dispatch(updateEncryptState({password}));
    }

    const decryptPrivatekey = (password: string, privateKey: string) => {
        return decrypt(password, privateKey);
    }

    return {
        showEncryptDialog,
        addWalletEncrypt,
        decryptPrivatekey,
        updatePassword,
        encryptState,
    }
}
export default useEncrypt;
