import {useToast, VStack} from "@chakra-ui/react";
import React, {useState} from "react";
import styled from "styled-components";
import FloatBottomBtn from "../../../components/FloatBottomBtn";
import NavigationBar from "../../../components/NavigationBar";
import TokenSelection from "../../../components/TokenSelection";
import {useAppDispatch, useAppSelector} from "../../../hooks/store";
import {RootState} from "../../../store/rootStore";
import TokenItem from "../components/TokenItem";
import {useNavigate} from "react-router-dom";
import {setTransfer} from "../../../reducer/appSlice";

const SelectToken = () => {
  const {currentWallet} = useAppSelector(
    (state: RootState) => state.app
  );
  const balance = currentWallet?.balance || ''
  const dispatch = useAppDispatch()
  const erc2Tokens = currentWallet?.erc20Tokens || []
  const [currentToken, setCurrentToken] = useState<string>()
  const navigate = useNavigate()
  const toast = useToast()

  const onNextStep = () => {
    if (currentToken === 'ETH') {
      dispatch(setTransfer({
        tokenName: 'ETH',
        symbol: 'ETH',
        from: currentWallet?.address,
        type: "ETH",
        balance: currentWallet?.balance
      }))
    } else {
      const tokenArray = erc2Tokens.filter(item => item.name === currentToken)
      if (tokenArray && tokenArray.length === 1) {
        const token = tokenArray[0]
        dispatch(setTransfer({
          tokenName: token.name || '',
          from: currentWallet?.address,
          contractAddress: token.contractAddress,
          decimal: token.decimal,
          symbol: token.symbol,
          type: "ERC20",
          balance: token.balance
        }))
      }
    }
    navigate('/my/transfer', {
      replace: true
    })
  }

  return (
    <div>
      <NavigationBar title="选择资产类型"/>
      <Container>
        <VStack flex={1}>
          {erc2Tokens?.map((erc20) => (
            <TokenSelection
              isSelected={currentToken === erc20.name}
              onClick={() => {
                if (Number(erc20.balance) === 0) {
                  toast({
                    position: 'top',
                    title: '余额不足，无法进行转账',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                  })
                } else {
                  setCurrentToken(erc20.name);
                }
              }}
              token={{
                name: erc20.name || "",
                balance: erc20.balance || "",
              }}
              key={erc20.contractAddress}
            />
          ))}
        </VStack>
      </Container>
      <FloatBottomBtn title="下一步" onClick={onNextStep}/>
    </div>
  );
};

const Container = styled.div`
  padding: 22px;
  overflow: hidden;
`;

export default SelectToken;
