import React, {useEffect, useState} from "react";
import styled from "styled-components";
import NavigationBar from "../../components/NavigationBar";
import {THEME} from "../../config/theme";
import InputItem from "../../components/InputItem";
import WhiteSpace from "../../components/WhiteSpace";
import {Text, Link, Checkbox, useToast, Textarea} from "@chakra-ui/react";
import FloatBottomBtn from "../../components/FloatBottomBtn";
import {Controller, useForm} from "react-hook-form";
import {generateMnemonic} from "bip39";
import {useAppDispatch} from "../../hooks/store";
import {useNavigate} from "react-router-dom";
import {newChive} from "../../reducer/appSlice";
import {deriveAccountFromWalletInput} from "../../utils/ethereumUtil";
import Wallet from "ethereumjs-wallet";
import {Wallet as EthersWallet} from "@ethersproject/wallet";
import useEncrypt from "../../hooks/useEncrypt";

const RestoreWallet = () => {
  const [agree, setAgree] = useState(false)
  const toast = useToast()
  const { showEncryptDialog, addWalletEncrypt, encryptState } = useEncrypt();
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const {control} = useForm({
    defaultValues: {
      name: '',
      password: '',
      confirmPassword: ''
    }
  });
  const onSubmit = async () => {
    const {name, password, confirmPassword} = control._formValues
    if (!name) {
      toast({
        position: 'top',
        title: '助记词',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return
    }
    if (!password || !confirmPassword) {
      toast({
        position: 'top',
        title: '请设置密码',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return;
    }
    if (password !== confirmPassword) {
      toast({
        position: 'top',
        title: '密码不一致',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return;
    }
    if (!agree) {
      toast({
        position: 'top',
        title: '请同意服务条款',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return;
    }
    const { wallet, type, walletType, isHDWallet } = await deriveAccountFromWalletInput(name);
    if(isHDWallet) {
      const hdWallet = wallet as Wallet;
      addWalletEncrypt(hdWallet.getAddressString(), hdWallet.getPrivateKeyString(), password)
    } else {
      const ethersWallet = wallet as EthersWallet
      addWalletEncrypt(ethersWallet.address, ethersWallet.privateKey, password);
    }
    navigate('/my/wallet')
  }

  return (
    <div>
      <NavigationBar title='导入钱包'/>
      <Container>
        <Controller
          name='name'
          control={control}
          render={({field}) => {
            return (
              <InputItem
                resize='none'
                multiLine
                title='助记词'
                placeholder='输入助记词单词，并使用空格分隔'
                {...field}
              />
            )
          }}
        />

        <WhiteSpace/>
        <Controller
          name='password'
          control={control}
          render={({field}) => {
            return (
              <InputItem
                title='创建密码'
                type='password'
                placeholder='输入密码'
                {...field}
              />
            )
          }}
        />

        <Controller
          name='confirmPassword'
          control={control}
          render={({field}) => {
            return (
              <InputItem
                type='password'
                placeholder='重复输入密码'
                {...field}
              />
            )
          }}
        />
        <WhiteSpace height={64}/>
        <Checkbox
          colorScheme='green'
          checked={agree}
          onChange={(e)=>{
            setAgree(e.target.checked)
          }}>
          <Text className='hint'>
            我已阅读并同意 {' '}
            <Link color={THEME.color.primary.default} href='#'>
              Web3.0 Lab 服务条款
            </Link>
          </Text>
        </Checkbox>
      </Container>
      <FloatBottomBtn title='确认' onClick={onSubmit}/>
    </div>
  )
}

export default RestoreWallet

const Container = styled.div`
  padding: 16px;

  .page-title {
    color: ${THEME.color.gray["#4-085"]};
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    font-feature-settings: 'case' on;
  }

  .page-desc {
    color: ${THEME.color.gray["#2-045"]};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    font-feature-settings: 'case' on;
    margin-top: 8px;
  }

  .hint {
    color: ${THEME.color.gray["#2-045"]};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    font-feature-settings: 'case' on;
  }
`
