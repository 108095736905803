import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import CardItem from "../components/CardItem";
import FloatBottomBtn from "../components/FloatBottomBtn";
import NavigationBar from "../components/NavigationBar";
import {THEME} from "../config/theme";
import {useAppDispatch, useAppSelector} from "../hooks/store";
import {
  useCheckFundsMutation,
  useLazyGetCardListQuery,
  useLazyGetOrderInfoQuery,
  usePaymentMutation, usePlaceOrderMutation
} from "../reducer/apiSlice";
import numeral from 'numeral'
import {setAfterPayPath} from "../reducer/appSlice";
import {RootState} from "../store/rootStore";
import {isSafari} from "../utils/Helpers";
import {OrderStatus, OrderType} from "./my/components/OrderItem";
import {Switch} from '@chakra-ui/react'

const newCardItem = {
  cardNo: '使用新卡支付',
  cardType: 'new',
  cardToken: 'new',
}

/**
 * 充值页面
 * state:{value: number}  入参：支付金额
 * 流程：
 * 1，获取钱包法币余额
 * 2，计算差额
 *    2.1 没有余额：只显示支付列表
 *    2.2 余额不足：显示余额抵扣 + 支付列表
 *    2.3 余额充足：显示余额抵扣 或者 支付列表
 * 3，点击确认付款，
 *    3.1 余额支付：继续回调 afterPay route
 *    3.2 银行支付：根据支付金额生成充值订单，支付完成后 回到 支付成功页
 *
 * **/
const PaymentPage = () => {
  const {currentWallet, afterPay} = useAppSelector(
    (state: RootState) => state.app
  );
  const dispatch = useAppDispatch()
  const [getOrderInfo, {data}] = useLazyGetOrderInfoQuery()
  const [getCardList, cardList] = useLazyGetCardListQuery()
  const [getBalance, {data: balanceData}] = useCheckFundsMutation()
  const [placeOrder, {data: placeOrderData}] = usePlaceOrderMutation()
  const [useBalance, setUseBalance] = useState(true)
  const [getPayment, {data: paymentResult}] = usePaymentMutation()
  const location = useLocation()
  const [paidValue, setPaidValue] = useState<number>(location.state.value)
  const navigate = useNavigate()
  const [canNext, setCanNext] = useState(false)
  const [paymentCard, setPaymentCard] = useState('')
  const timer = useRef<any>()

  /**
   * 已选择支付方式
   * **/
  useEffect(() => {
    if (location.state.type === OrderType.CONSUME) {
      if ((!!paymentCard || paidValue === 0)) {
        setCanNext(true)
      } else {
        setCanNext(false)
      }
    } else if (location.state.type === OrderType.TOP_UP) {
      // can be simplified
      if ((!!paymentCard)) {
        setCanNext(true)
      } else {
        setCanNext(false)
      }
    }

  }, [paymentCard, paidValue])

  /**
   * 初始化，获取余额
   * **/
  useEffect(() => {
    if (location.state.type === OrderType.CONSUME) {
      getBalance({
        address: currentWallet?.address || ''
      })
    } else {
      setPaidValue(location.state.value)
    }
  }, [currentWallet?.address])

  /**
   * 根据余额设置需要支付的金额
   * **/
  useEffect(() => {
    if (balanceData) {
      const paid = (balanceData?.data > location.state.value) ? 0 : (location.state.value - balanceData?.data)
      setPaidValue(paid)
      setUseBalance(balanceData?.data > 0)
      setCanNext(paid === 0)
    }

  }, [balanceData])


  /**
   * 检查订单状态
   * **/
  const checkOrderStatus = async () => {
    timer.current = setTimeout(() => {
      getOrderInfo(placeOrderData?.data.id)
      checkOrderStatus()
    }, 1000)
  }

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [])


  /**
   * 获取支付方式
   * **/
  useEffect(() => {
    if (currentWallet?.address) {
      getCardList(currentWallet.address)
    }
  }, [currentWallet?.address])


  /**
   * 支付完成后
   * **/
  useEffect(() => {
    if (data?.data) {
      const status = data.data.status
      if (status === OrderStatus.PAID) {
        if (afterPay) {
          navigate(afterPay.route, {
            replace: true
          })
        } else {
          navigate('/my/topUpHistory', {
            replace: true
          })
        }
      }
    }
  }, [data?.data])

  /**
   * 根据用户选择，确定需要付款的金额
   * **/
  useEffect(() => {
    if (location.state.type === OrderType.CONSUME) {
      if (useBalance) {
        setPaidValue((balanceData?.data > location.state.value) ? 0 : (location.state.value - balanceData?.data))
      } else {
        setPaidValue(location.state.value)
      }
    }
  }, [useBalance])

  /**
   *  请求支付参数
   * **/
  const gotoPayment = async () => {
    if (paidValue === 0) {
      if (afterPay) {
        navigate(afterPay.route, {
          replace: true
        })
      }
    } else if (paidValue > 0) {
      placeTopUpOrder()
    }
  }

  /**
   * 创建充值订单
   * **/
  const placeTopUpOrder = async () => {
    const params: any = {
      address: currentWallet?.address,
      value: Number(paidValue),
      type: OrderType.TOP_UP
    }
    placeOrder(params)
  }

  /**
   * 监听订单创建结果
   * **/
  useEffect(() => {
    if (placeOrderData && placeOrderData.code === 0 && placeOrderData.data) {
      getPayment({
        orderId: placeOrderData.data.id,
        cardId: paymentCard,
        redirectRoute: afterPay?.route || '/my/topup'
      })
    }
  }, [placeOrderData])


  /**
   * 跳转至支付页面
   * **/
  useEffect(() => {
      if (paymentResult && paymentResult.data) {
        if (paymentResult.data.url) {
          if (timer.current) {
            clearTimeout(timer.current)
          }
          checkOrderStatus()
          // !Important: 在iOS系统上，Safari限制自动打开新页面，所以使用以下 work around， ugly but useful
          setTimeout(() => {
            if (isSafari){
              let a: any = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = paymentResult.data.url;
              a.click();
              document.body.removeChild(a);
            }else {
              window.open(paymentResult.data.url);
            }
          })
        }
      }
  }, [paymentResult])

  return (
    <React.Fragment>
      <NavigationBar title='支付' showBackIcon/>
      <Container>
        <div className='price'>{numeral(location.state.value).divide(100).format('$0,0.00')}</div>
        <div className='desc'>服务费充值</div>
        <div className='card-list'>
          {(balanceData && balanceData.data && balanceData.data > 0 && location.state.type === OrderType.CONSUME) ? (
            <div className='balance'>
              <div>
                余额抵扣 {balanceData.data > location.state.value ? numeral(location.state.value).divide(100).format('$0,0.00') : numeral(balanceData.data).divide(100).format('$0,0.00')}
              </div>
              <Switch isChecked={useBalance} onChange={e => setUseBalance(e.target.checked)} colorScheme='green'/>
            </div>
          ): null}
          {paidValue > 0 && (
            <React.Fragment>
              {(cardList && cardList.data && cardList.data.data.length > 0) ? cardList.data.data.map(item => (
                <CardItem key={item.id} isSelect={paymentCard === item.cardToken} info={item} onClick={(token) => {
                  setPaymentCard(token)
                }}/>
              )): null}
              <CardItem isSelect={paymentCard === 'new'} info={newCardItem} onClick={(token) => {
                setPaymentCard(token)
              }}/>
            </React.Fragment>
          )}

        </div>
      </Container>
      <FloatBottomBtn title={`确认支付${paidValue > 0 ? numeral(paidValue).divide(100).format('$0,0.00') : ''}`}
                      disable={!canNext} onClick={gotoPayment}/>
    </React.Fragment>
  )
}

export default PaymentPage


const Container = styled.div`
  padding: 24px;

  .balance {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    padding: 16px;
    border-radius: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    justify-content: space-between;
  }

  .price {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    align-items: center;
    color: ${THEME.color.gray["#4-085"]};
  }

  .desc {
    margin-top: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    color: ${THEME.color.gray["#2-045"]};
  }

  .card-list {
    margin-top: 24px
  }
`;
