import { Box, Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, useDisclosure, VStack } from "@chakra-ui/react";
import * as React from "react";
import { RootState } from "../../../store/rootStore";
import { useAppDispatch, useAppSelector } from "../../../hooks/store";
import TokenItem from "./TokenItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import useBalance from "../../../hooks/useBalance";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addERC20Token } from "../../../reducer/appSlice";
import useERC20Transfer from "../../../hooks/useERC20";


export interface IMyTokenProps {}

export default function MyToken(props: IMyTokenProps) {
  const {currentWallet } = useAppSelector((state: RootState) => state.app);
  useBalance(true);
  //useERC20Transfer();
  const balance = currentWallet?.balance || ''
  const erc2Token = currentWallet?.erc20Tokens || []
  const navigate = useNavigate();
  const handleTransferClick = (contract?: string) => {
    navigate(`/send-token?contract=${contract}`);
  };

  const dispatch = useAppDispatch();

  const [inputWallet, setInputWallet] = React.useState('');
  const handleOnInputWalletChange = (event: React.ChangeEvent<HTMLInputElement>) => setInputWallet(event?.target.value);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleAddERC20TokenClick = () => {
    onOpen();
  }

  const handleAddERC20TokenConfirm = () => {
    dispatch(addERC20Token(inputWallet))
    onClose();
  }

  return (
    <Box minH="100vh" fontSize="xl">
        <VStack flex={1}>
            <TokenItem
                onClick={() => handleTransferClick("")}
                price=""
                balance={balance}
                symbol="ETH"
                name="Ethereum"
                icon={<FontAwesomeIcon size="2x" icon={brands("ethereum")} />}
            />
            {erc2Token?.map(erc20 =>
            <TokenItem
                key={erc20.contractAddress}
                onClick={() => handleTransferClick(erc20.contractAddress)}
                price=""
                balance={erc20.balance || ""}
                symbol={erc20.symbol || ""}
                name={erc20.name || ""}
                icon={<FontAwesomeIcon size="2x" icon={brands("ethereum")} />}
            />)}
            <Button onClick={handleAddERC20TokenClick}>add ERC20 Token</Button>
        </VStack>
      <Box
        position="absolute"
        bottom="6"
        right="6"
        onClick={() => handleTransferClick("")}
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="full"
        bg="blue.300"
        width="14"
        height="14"
        >
        <FontAwesomeIcon size="lg" icon={solid("paper-plane")} />
    </Box>
    <Modal onClose={onClose} size="xs" isOpen={isOpen}>
        <ModalBody />
        <ModalContent>
          <ModalHeader>Input ERC20 token contract address</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input value={inputWallet} onChange={handleOnInputWalletChange} variant='outline' placeholder='Input ERC20 token contract address' />
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleAddERC20TokenConfirm}>Confirm</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
