import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import FloatBottomBtn from "../../components/FloatBottomBtn";
import InputItem from "../../components/InputItem";
import NavigationBar from "../../components/NavigationBar";
import {THEME} from "../../config/theme";
import {OrderType} from "./components/OrderItem";

const TopUp = () => {
  const [topValue, setTopValue] = useState<string>('')
  const [canNext, setCanNext] = useState(false)
  const navigate = useNavigate()


  useEffect(() => {
    const value = Number(topValue)
    if (topValue && typeof value === 'number' && !isNaN(value)){
      setCanNext(true)
    }else {
      setCanNext(false)
    }
  }, [topValue])

  const placeTopUpOrder = async ()=>{
    navigate('/payment', {
      state:{
        value: Number(topValue) * 100,
        type: OrderType.TOP_UP
      }
    })
  }

  return (
    <React.Fragment>
        <NavigationBar title='服务费充值' showBackIcon/>
      <Container>
        <InputItem
          title='充值金额'
          placeholder='请输入充值金额'
          value={topValue}
          addonleft={(<span style={{color: THEME.color.gray["#2-045"]}}>$</span>)}
          onChange={e => {
            setTopValue(e.target.value)
          }}
        />
      </Container>
      <FloatBottomBtn title='下一步' disable={!canNext} onClick={placeTopUpOrder}/>
    </React.Fragment>
  )
}

export default TopUp

const Container = styled.div`
  padding: 24px;

`;
