import {CopyIcon, HamburgerIcon} from "@chakra-ui/icons";
import {Box, Button, Grid, GridItem, HStack, Image, Text, useDisclosure, useToast, VStack,} from "@chakra-ui/react";
import {brands} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ethers} from "ethers";
import React, {useEffect, useRef} from "react";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import BottomTabBar from "../../components/BottomTabBar";
import WhiteSpace from "../../components/WhiteSpace";
import {THEME} from "../../config/theme";
import {useAppDispatch, useAppSelector} from "../../hooks/store";
import {useNewChiveMutation} from "../../reducer/apiSlice";
import {setCurrentContract, setTransfer, switchWallet, updateToken} from "../../reducer/appSlice";
import {useCheckTokenByWalletQuery, useGetBalanceByAddressQuery} from "../../reducer/chainSlice";
import {RootState} from "../../store/rootStore";
import money from "../../svg/money.svg";
import newToken from "../../svg/new-token.svg";
import roundPlus from "../../svg/round-plus.svg";
import trans from "../../svg/trans.svg";
import top_up from "../../svg/top_up.svg";
import TokenItem from "./components/TokenItem";
import WalletsListModal from "./components/WalletsListModal";
import {formatAddressWithDot} from "../../utils/Helpers";

export const DEFAULT_WALLET_ICON =
  "https://res.cloudinary.com/shenmanz/image/upload/v1664922469/web3_lab/default.png";

type NextType = 'NEW_CONTRACT' | 'NA'

const MyWallet = () => {
  const {currentWallet, pendingContract} = useAppSelector(
    (state: RootState) => state.app
  );
  const erc2Tokens = currentWallet?.erc20Tokens || []
  const {isOpen, onOpen, onClose} = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const nextStep = useRef<NextType>('NA')
    const timer = useRef<any>()

  const handleOnCopySuccess = () => {
    toast({
      title: "地址已经复制",
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
  };
  const { data, isFetching, refetch: refetchToken} = useCheckTokenByWalletQuery(
    currentWallet?.address
  );

  // const { data: ethBalance, refetch: refetchBalance } = useGetBalanceByAddressQuery(
  //   currentWallet?.address
  // );

  const [checkNewChive] = useNewChiveMutation()

  /**
   * 检查新韭菜余额
   * **/
  useEffect(() => {
    if (currentWallet && currentWallet.address) {
      checkNewChive(currentWallet.address)
    }
  }, [])

  useEffect(() => {
    if (data && data.result.length > 0) {
      const formatResult = data.result.map(item => {
        return {
          ...item,
          balance: ethers.utils.formatUnits(item.balance, 18),
          decimal: item.decimals
        }
      })
      dispatch(updateToken(formatResult));
    }
  }, [data]);

  const onModalClose = ()=>{
    onClose()
    if (nextStep.current === 'NEW_CONTRACT'){
      navigate('/my/new-token', {
        replace: true
      })
    }
  }

  const poll = () => {
    timer.current = setTimeout(() => {
      refetchToken()
      // refetchBalance()
      poll()
    }, 3000)
  }

  useEffect(() => {
    poll()
    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [])

  return (
    <Container>
      <NavigationContainer>
        <div className="placeholder"></div>
        <div className="navigation">
          <div className="left-icon" onClick={onOpen}>
            <HamburgerIcon color="white" fontSize={16}/>
          </div>
          <div className="title">钱包</div>
        </div>
      </NavigationContainer>
      <WhiteSpace height={24}/>
      <Box className="card" minH="156px">
        <Image
          style={{marginTop: -32}}
          boxSize="64px"
          src={currentWallet?.icon || DEFAULT_WALLET_ICON}
          alt="Icon"
        />
        <Text mt="8px" className="name">
          {currentWallet?.name}
        </Text>
        <Text className="address" mt="8px">{formatAddressWithDot(currentWallet?.address)}</Text>
        <CopyToClipboard
          text={currentWallet?.address || ""}
          onCopy={handleOnCopySuccess}
        >
          <Button
            mt="12px"
            className="copy-btn"
            leftIcon={<CopyIcon/>}
            variant="ghost"
          >
            复制地址
          </Button>
        </CopyToClipboard>

        <Image
          onClick={() => {
            navigate('/my/walletSetting')
          }}
          className="more"
          boxSize="22px"
          src="https://res.cloudinary.com/shenmanz/image/upload/v1664925288/web3_lab/more.png"
          alt="Icon"
        />
      </Box>
      <Grid className="tab" templateColumns="repeat(4, 1fr)" mt="12px">
        <GridItem
          onClick={() => {
            navigate("/my/select-token");
          }}
          className="tab-item"
          w="100%"
          h="48px"
        >
          <img src={trans} alt="logo"/>
          <Text>转账</Text>
        </GridItem>
        <GridItem
          className="tab-item"
          style={{
            borderLeft: `1px solid ${THEME.color.primary.border}`,
            borderRight: `1px solid ${THEME.color.primary.border}`,
          }}
          w="100%"
          h="48px"
          onClick={() => {
            navigator.clipboard.writeText(currentWallet?.address || "").then(function () {
              handleOnCopySuccess()
            }, function (err) {
              console.error('Async: Could not copy text: ', err);
            });
          }}
        >
          <img src={money} alt="logo"/>
          <Text>收款</Text>
        </GridItem>
        <GridItem
          style={{
            borderRight: `1px solid ${THEME.color.primary.border}`,
          }}
          className="tab-item" w="100%" h="48px"
          onClick={() => {
            nextStep.current = 'NEW_CONTRACT'
            onOpen()
          }}
        >
          <img src={newToken} alt="logo"/>
          <Text>发币</Text>
        </GridItem>
        <GridItem
          className="tab-item" w="100%" h="48px"
          onClick={() => {
            navigate('/my/topUpHistory')
          }}
        >
          <img src={top_up} alt="logo"/>
          <Text>充值</Text>
        </GridItem>
      </Grid>
      <WhiteSpace height={24}/>
      <HStack alignItems="center" justify="space-between">
        <Text>代币</Text>
        <img
          onClick={() => {
            navigate("/my/new-contract");
          }}
          style={{width: 24, height: 24}}
          src={roundPlus}
          alt="logo"
        />
      </HStack>
      <WhiteSpace height={8}/>
      <VStack flex={1}>
        {/*<TokenItem*/}
        {/*  onClick={() => {*/}
        {/*  }}*/}
        {/*  price=""*/}
        {/*  balance={balance}*/}
        {/*  symbol="LABETH"*/}
        {/*  name="LABETH"*/}
        {/*  icon={<FontAwesomeIcon size="2x" icon={brands("ethereum")}/>}*/}
        {/*/>*/}
        {pendingContract ? (
          <TokenItem
            key={pendingContract.address}
            onClick={() => {

            }}
            price=""
            balance={pendingContract.value}
            symbol={pendingContract.symbol}
            name={pendingContract.name}
            icon={<FontAwesomeIcon size="2x" icon={brands("ethereum")}/>}
          />
        ) : null}
        {erc2Tokens?.map((erc20) => (
          <TokenItem
            key={erc20.contractAddress}
            onClick={() => {
              dispatch(setCurrentContract(erc20))
              navigate("/my/transactions");
            }}
            price=""
            balance={erc20.balance || ""}
            symbol={erc20.symbol || ""}
            name={erc20.name || ""}
            icon={<FontAwesomeIcon size="2x" icon={brands("ethereum")}/>}
          />
        ))}
      </VStack>
      <WhiteSpace height={48 + 16}/>
      <BottomTabBar current="WALLET"/>
      <WalletsListModal
        onClose={onModalClose}
        onOK={onModalClose}
        open={isOpen}
        autoSelect={false}
        onSelect={(wallet) => {
          dispatch(switchWallet(wallet.address || ''))
        }}/>
    </Container>
  );
};

export default MyWallet;


const Container = styled.div`
  padding: 24px;

  .card {
    position: relative;
    padding-bottom: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${THEME.color.primary.default};
    border-radius: 8px;

    .more {
      color: white;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .name {
      color: white;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      font-feature-settings: "case" on;
    }

    .address {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      text-align: center;
      font-feature-settings: "case" on;
      color: rgba(255, 255, 255, 0.4);
    }

    .copy-btn {
      color: white;
      font-size: 12px;

      &:hover {
        background: none;
      }
    }
  }

  .tab {
    padding: 8px;
    background-color: #ffffff;
    border: 1px solid #eaecf5;
    border-radius: 8px;

    .tab-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      font-feature-settings: "case" on;
    }
  }
`;

const NavigationContainer = styled.div`
  .placeholder {
    height: 44px;
  }

  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 44px;
    z-index: 999;
    background: linear-gradient(180deg, #131b63 -18.9%, #481162 119.73%);
    display: flex;
    flex-direction: row;
    align-items: center;

    .left-icon {
      width: 36px;
      height: 36px;
      margin-left: 16px;
      z-index: 1;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    .title {
      text-align: center;
      flex: 1;
      color: white;
      margin-left: -52px;
      font-size: 18px;
      font-weight: 400;
    }
  }
`;
