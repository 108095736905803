import { Box, HStack, Text, VStack, Checkbox } from "@chakra-ui/react";
import { brands } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { THEME } from "../config/theme";
import {formatBalance} from "../utils/Helpers";

type TokenSelectionProps = {
  token: {
    name: string;
    balance: string;
  };
  isSelected?: boolean;
  onClick: () => void;
};

const TokenSelection = (props: TokenSelectionProps) => {
  return (
    <Container>
      <HStack
        width="100%"
        onClick={(e) => {
          e.preventDefault()
          if (props.onClick) {
            props.onClick();
          }
        }}
        p={1}
      >
        <Box
          bg="#EFF1F8"
          borderRadius="full"
          color="#5F6ABA"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="40px"
          height="40px"
        >
          <FontAwesomeIcon size="2x" icon={brands("ethereum")} />
        </Box>
        <HStack
          h="50px"
          alignItems="center"
          justify="space-between"
          style={{ borderBottom: "1px solid #EAECF5", flex: 1 }}
        >
          <VStack style={{alignItems: 'flex-start'}}>
            <Text className="info">{props.token.name}</Text>
            <Text className="balance">{formatBalance(props.token.balance) || "0.0"}</Text>
          </VStack>
          <Checkbox
            size="md"
            colorScheme="green"
            isChecked={props.isSelected}
          ></Checkbox>
        </HStack>
      </HStack>
    </Container>
  );
};

export default TokenSelection;

const Container = styled.div`
  width: 100%;
  .info {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-align: left;
    font-feature-settings: "case" on;
    color: ${THEME.color.gray["#4-085"]};
  }
  .balance {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-align: left;
    width: 100%;
    font-feature-settings: "case" on;
    color: ${THEME.color.gray["#2-045"]};
  }
`;
