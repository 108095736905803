export const THEME = {
  size: {
    SHOWCASE_ITEM_WIDTH: {
      MOBILE: 264,
      DESKTOP: 300,
    },
    HEADER_BAR_HEIGHT: "48px",
    BREAD_CRUMBS_HEIGHT: "18px",
    NAVBAR_HEIGHT: "48px",

    BASE_LAYER_HEIGHT: "209px",
    BASE_LAYER_HEIGHT_LOGIN: "188px",

    DIALOG_HEIGHT: "486px",
    DIALOG_WIDTH_MOBILE: "280px",
    DIALOG_WIDTH_DESKTOP: "588px",

    SIDE_MARGIN_WIDTH: "16px",
    BROWSER_MODE_THRESHOLD: "767px",
    MOBILE_SCREEN_MAX_WIDTH: "430px",
    SECTION_CONTENT_MAX_WIDTH: "1220px",
    // Todo: replace the following
    maxWidth: {
      section: "1220px",
      body: "1360px",
      footer: "1080px",
    },
  },

  color: {
    primary: {
      default: "#261863",
      variant: "#D975BB",
      variant2: "#D5A8C7",
      border: "#EAECF5",
      danger: '#CC1543'
    },
    gray: {
      "#1-025": "rgba(0,0,0,0.25)",
      "#2-045": "rgba(0,0,0,0.45)",
      "#3-065": "rgba(0,0,0,0.65)",
      "#4-085": "rgba(0,0,0,0.85)",
      "#5-087": "rgba(0,0,0,0.87)",
    },
    background: {
      gray: "#f5f5f5",
      yellow: "#FAEBC8" /* 初稿设计中 首页 Jumbotron 的背景色 */,
    },
    accent: {
      green: "#53a35d",
      yellow: "#eba33d",
      blue: "#2e7cf6",
      gold: "#d6b294",
    },
    link: "#61dafb",
  },
};
