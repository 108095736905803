import React, {forwardRef} from "react";
import styled from 'styled-components'
import {Input, InputGroup, InputLeftElement, Textarea} from "@chakra-ui/react";
import {THEME} from "../config/theme";


const InputItem = (props: any, ref: any) => {

  return (
    <Container>
      {props.title && (
        <div className='title'>
          <div className='left'>
            {props.title}
            {props.required ? (<span style={{color: 'red'}}>*</span>) : null}
          </div>
          {props.titleright ? (
            <div className='right'>
              {props.titleright}
            </div>
          ) : null}

        </div>
      )}
      {props.multiLine ? (
        <Textarea
          ref={ref}
          variant='outline'
          focusBorderColor='none'
          _placeholder={{opacity: 1, color: THEME.color.gray["#2-045"]}}
          style={{backgroundColor: 'white', border: "none", height: 64}}
          {...props}
        />
      ) : (
        <InputGroup>
          {props.addonleft && (
            <InputLeftElement
              style={{backgroundColor: 'white', border: "none", height: 64}}
              pointerEvents='none'
            >
              {props.addonleft}
            </InputLeftElement>
          )}
          <Input
            ref={ref}
            variant='outline'
            focusBorderColor='none'
            _placeholder={{opacity: 1, color: THEME.color.gray["#2-045"]}}
            style={{backgroundColor: 'white', border: "none", height: 64}}
            {...props}
          />
        </InputGroup>
      )}

    </Container>
  )
}

export default forwardRef(InputItem)

const Container = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;

  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    font-feature-settings: 'case' on;
    color: ${THEME.color.gray["#2-045"]};
    margin-bottom: 8px;
    flex-direction: row;
    justify-content: space-between;
  }
`
