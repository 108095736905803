import React from "react";
import {Controller, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import FloatBottomBtn from "../../components/FloatBottomBtn";
import InputItem from "../../components/InputItem";
import NavigationBar from "../../components/NavigationBar";
import WhiteSpace from "../../components/WhiteSpace";
import {useAppDispatch, useAppSelector} from "../../hooks/store";
import {addERC20Token} from "../../reducer/appSlice";
import {RootState} from "../../store/rootStore";

const AddContract = () => {
  const {currentWallet} = useAppSelector(
    (state: RootState) => state.app
  );
  const erc2Tokens = currentWallet?.erc20Tokens || []
  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  const {control} = useForm({
    defaultValues: {
      address: "",
      symbol: "",
      decimal: "",
    },
  });
  return (
    <div>
      <NavigationBar title="自定义代币"/>
      <Container>
        <Controller
          name="address"
          control={control}
          render={({field}) => {
            return (
              <InputItem
                title="代币合约地址"
                placeholder="输入代币合约地址"
                {...field}
              />
            );
          }}
        />

        <WhiteSpace/>
        <Controller
          name="symbol"
          control={control}
          render={({field}) => {
            return <InputItem title="Symbol" {...field} />;
          }}
        />

        <Controller
          name="decimal"
          control={control}
          render={({field}) => {
            return <InputItem title="Decimal" {...field} />;
          }}
        />
      </Container>
      <FloatBottomBtn title="保存" onClick={() => {
        const {address} = control._formValues;
        if (erc2Tokens && erc2Tokens.filter((item) => item.contractAddress === address).length > 0) {
          return
        }
        dispatch(addERC20Token(address));
        navigate(-1);
      }}/>
    </div>
  );
};

export default AddContract;

const Container = styled.div`
  padding: 22px;
`;
