import { Box, Button, Input, Text, VStack } from '@chakra-ui/react';
import { parseUnits } from 'ethers/lib/utils';
import * as React from 'react';
import { useSelector } from 'react-redux';
import EncryptDiloag from '../../../components/EncryptDialog';
import useEncrypt from '../../../hooks/useEncrypt';
import { RootState } from '../../../store/rootStore';
import { deploy } from '../../../utils/etherUtils';

export interface IDeployTokenProps {
}

export default function DeployToken (props: IDeployTokenProps) {

    const { currentWallet, network } = useSelector((rootState: RootState) => rootState.app)

    const [tokenName, setTokenName] = React.useState('');
    const [tokenSymbol, setTokenSymbol] = React.useState('');
    const [supply, setSupply] = React.useState(0);
    const [contractAddress, setContractAddress] = React.useState('');
    const [loading, setLoading] = React.useState(false);


    const handleTokenNameChange = (event: any) => {
        setTokenName(event.target.value);
    }
    const handleTokenSymbolChange = (event: any) => {
        setTokenSymbol(event.target.value);
    }
    const handleTokenSupplyChange = (event: any) => {
        setSupply(event.target.value);
    }

    const { showEncryptDialog, decryptPrivatekey } = useEncrypt();

    const handlePasswordConfirm = async (password: string) => {
        if(!currentWallet) {
            return;
        }
        setLoading(true);
       const contract =  await deploy(network, decryptPrivatekey(password, currentWallet?.privateKey || ''), 'SampleERC20', [tokenName, tokenSymbol, parseUnits(supply+"")]);
       console.log('contract', contract);
       setContractAddress(contract.address);
       setLoading(false);
    }

    const handleDeployToken = async () => {
        showEncryptDialog(true, "INPUT_PASSWORD");
    }

  return (
    <Box minH="100vh" p={2}>
        <VStack>
            <Input value={tokenName} onChange={handleTokenNameChange} placeholder="token name" />
            <Input value={tokenSymbol} onChange={handleTokenSymbolChange} placeholder="token symbol" />
            <Input value={supply} onChange={handleTokenSupplyChange} placeholder="token supply" />
            <Button isLoading={loading} onClick={handleDeployToken}>Confirm</Button>
            <Text>{contractAddress}</Text>
        </VStack>
        <EncryptDiloag onConfirm={handlePasswordConfirm} />
    </Box>
  );
}
