// @typescript-eslint/
const ENC_KEY = "bf3c199c2470cb477d907b1e0917c17b"; // set random encryption key
const IV = "5183666c72eec9e4"; // set random initialisation vector
// ENC_KEY and IV can be generated as crypto.randomBytes(32).toString('hex');

const phrase = "who let the dogs out";

export const encrypt = ((password: string,val: string): string => {
    const aes256 = require('aes256');
    return aes256.encrypt(password, val);
});

export const decrypt = ((password: string,encrypted: string): string => {
    const aes256 = require('aes256');
    return aes256.decrypt(password, encrypted);
});