import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {apiAddress} from "../config/system";
import {OrderType} from "../pages/my/components/OrderItem";

interface ResponseType<T> {
  msg: string,
  data: T,
  code: number
}

interface IPlaceOrder {
  address: string,
  value: number,
  type: OrderType
}

interface IPayment {
  orderId: number,
  cardId: string,
  redirectRoute: string
}

interface ICheckFunds {
  address: string,
}

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: apiAddress,
    prepareHeaders: (headers) => {
      return headers
    },
  }),
  keepUnusedDataFor: 3,
  endpoints: (builder) => {
    return {
      newChive: builder.mutation<ResponseType<any>, string | undefined>({
        query: (address: string) => ({
          url: `/app/newChive`,
          method: 'post',
          body: {address: address},
        })
      }),
      getOrderHistory: builder.query<ResponseType<any>, string | undefined>({
        query: (address: string) => ({
          url: `/app/history`,
          params: {
            address
          }
        }),
      }),
      checkFunds: builder.mutation<ResponseType<any>, ICheckFunds>({
        query: (params: ICheckFunds) => ({
          url: `/app/check-funds`,
          method: 'post',
          body: params,
        })
      }),
      placeOrder: builder.mutation<ResponseType<any>, IPlaceOrder>({
        query: (order: IPlaceOrder) => ({
          url: `/app/placeOrder`,
          method: 'post',
          body: order,
        })
      }),
      payment: builder.mutation<ResponseType<any>, IPayment>({
        query: (params: IPayment) => ({
          url: `/app/payment`,
          method: 'post',
          body: params,
        })
      }),
      getOrderInfo: builder.query<ResponseType<any>, number>({
        query: (orderId: number) => ({
          url: `/app/order`,
          params: {
            id: orderId
          }
        }),
      }),
      getCardList: builder.query<ResponseType<any>, string | undefined>({
        query: (address) => ({
          url: `/app/getCards`,
          params:{
            address: address
          }
        }),
      }),
    }
  },
})

export const {
  usePlaceOrderMutation,
  usePaymentMutation,
  useLazyGetOrderHistoryQuery,
  useLazyGetOrderInfoQuery,
  useLazyGetCardListQuery,
  useCheckFundsMutation,
  useNewChiveMutation
} = apiSlice




