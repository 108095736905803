import { CheckIcon } from "@chakra-ui/icons";
import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import NavigationBar from "../../components/NavigationBar";
import { Text, Divider } from "@chakra-ui/react";
import dayjs from "dayjs";
import { THEME } from "../../config/theme";
import WhiteSpace from "../../components/WhiteSpace";
import { ethers } from "ethers";

const TransactionDetail = () => {
  const location = useLocation();
  const info = location.state;
  return (
    <React.Fragment>
      <NavigationBar title="详情" />
      <Container>
        <Header>
          <CheckIcon className="icon" fontSize="56px"></CheckIcon>
          <Text className="title" mt="8px">
            成功
          </Text>
          <Text className="time" mt="8px">
            {" "}
            {dayjs(Number(info.timeStamp) * 1000).format(
              "YYYY年M月D日 HH:mm:ss"
            )}
          </Text>
        </Header>
        <WhiteSpace />
        <InfoItem
          info={[
            {
              label: "数量",
              value:
                ethers.utils.formatUnits(info.value, info.tokenDecimal) +
                " " +
                info.tokenSymbol,
            },
          ]}
        />
        <WhiteSpace />
        <InfoItem
          info={[
            {
              label: "服务费",
              value:
                ethers.utils.formatUnits(info.gas, info.tokenDecimal) +
                " " +
                info.tokenSymbol,
            },
            {
              label: "收款地址",
              value: info.to,
            },
            {
              label: "付款地址",
              value: info.from,
            },
          ]}
        />
        <WhiteSpace />
        <InfoItem
          info={[
            {
              label: "交易号",
              value: info.hash,
            },
          ]}
        />
        <WhiteSpace />
      </Container>
    </React.Fragment>
  );
};

export default TransactionDetail;

type InfoItemProps = {
  info: {
    label: string;
    value: string;
  }[];
};

const InfoItem = (props: InfoItemProps) => {
  return (
    <React.Fragment>
      <InfoItemContainer>
        {props.info.map((item, index) => {
          return (
            <React.Fragment>
              <div className="item">
                <Text className="left">{item.label}</Text>
                <Text className="right">{item.value}</Text>
              </div>
              {index !== props.info.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </InfoItemContainer>
    </React.Fragment>
  );
};

const InfoItemContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  padding-bottom: 0;
  .item {
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
    flex-direction: row;
    justify-content: space-between;
    .left {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      font-feature-settings: "case" on;
      color: ${THEME.color.gray["#2-045"]};
    }
    .right {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      max-width: 70%;
      text-align: right;
      word-wrap: break-word;
      font-feature-settings: "case" on;
      color: ${THEME.color.gray["#4-085"]};
    }
  }
`;

const Container = styled.div`
  padding: 22px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .icon {
    font-size: 56px;
    background-color: #07c160;
    color: white;
    border-radius: 28px;
    padding: 12px;
  }
  .title {
    color: #07c160;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    font-feature-settings: "case" on;
  }
  .time {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    font-feature-settings: "case" on;
    color: ${THEME.color.gray["#2-045"]};
  }
`;
