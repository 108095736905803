import {Image, useDisclosure} from "@chakra-ui/react";
import React, {useRef} from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import BottomTabBar from "../../components/BottomTabBar";
import ListItem from "../../components/ListItem";
import NavigationBar from "../../components/NavigationBar";
import WhiteSpace from "../../components/WhiteSpace";
import {useAppDispatch} from "../../hooks/store";
import {switchWallet, WalletState} from "../../reducer/appSlice";
import manage_wallet from "../../svg/me/manage_wallet.svg";
import manage_new_contract from "../../svg/me/manage_new_contract.svg";
import manage_about from "../../svg/me/manage_about.svg";
import WalletsListModal from "./components/WalletsListModal";

enum NEXT_STEP_TYPE {
  'WALLET_SETTING',
  'NEW_CONTRACT'
}

const MyPage = () => {
  const navigate = useNavigate()
  const {isOpen, onOpen, onClose} = useDisclosure();
  const selectedWallet = useRef<WalletState>()
  const nextStep = useRef<NEXT_STEP_TYPE>(NEXT_STEP_TYPE.WALLET_SETTING)
  const dispatch = useAppDispatch()

  return (
    <Container>
      <NavigationBar title='我的' showBackIcon={false}/>
      <ListItem
        icon={(<Image
          className='image'
          boxSize="32px"
          src={manage_wallet}
          alt="Icon"
        />)}
        title='管理钱包'
        onClick={() => {
          nextStep.current = NEXT_STEP_TYPE.WALLET_SETTING
          onOpen()
        }}/>
      <WhiteSpace/>
      <ListItem
        icon={(<Image
          className='image'
          boxSize="32px"
          src={manage_new_contract}
          alt="Icon"
        />)}
        title='我要发币'
        onClick={() => {
          nextStep.current = NEXT_STEP_TYPE.NEW_CONTRACT
          onOpen()
        }}/>
      <WhiteSpace/>
      <ListItem
        icon={(<Image
          className='image'
          boxSize="32px"
          src={manage_about}
          alt="Icon"
        />)}
        title='关于Web 3.0 Lab'
        onClick={() => {

        }}/>
      <BottomTabBar current="ME"/>
      <WalletsListModal
        onClose={onClose}
        onOK={()=>{
          onClose()
          switch (nextStep.current) {
            case NEXT_STEP_TYPE.NEW_CONTRACT:{
              if (selectedWallet){
                dispatch(switchWallet(selectedWallet?.current?.address || ''))
                navigate('/my/new-token')
              }
              break
            }
            case NEXT_STEP_TYPE.WALLET_SETTING:{
              if (selectedWallet.current){
                navigate('/my/walletSetting',{
                  state:{
                    wallet: selectedWallet.current
                  }
                })
              }
              break
            }
          }

        }}
        open={isOpen}
        autoSelect={false}
        onSelect={(wallet) => {
          selectedWallet.current = wallet

        }}/>
    </Container>
  );
};

export default MyPage;


const Container = styled.div`
  padding: 24px;

`;

