import {
  Box,
  Grid,
  Input,
  Modal,
  Text,
  Image,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Heading,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import {Button} from '@chakra-ui/react'
import {generateMnemonic} from 'bip39';
import {ChangeEvent, useState} from 'react';
import Wallet from "ethereumjs-wallet";
import {Wallet as EthersWallet} from "@ethersproject/wallet";
import {useNavigate} from 'react-router-dom';
import {deriveAccountFromMnemonic, deriveAccountFromWalletInput} from "../../utils/ethereumUtil";
import useEncrypt from "../../hooks/useEncrypt";
import EncryptDialog from '../../components/EncryptDialog';
import HomePageBtn from "./components/Button";

export interface IAccountProps {
}

export default function Homepage(props: IAccountProps) {

  const navigate = useNavigate();
  const handleAddExisintWallet = async () => {
    navigate('/restore-wallet')
  }

  const handleCreateNewWallet = () => {
    // showEncryptDialog(true, "SET_PASSWORD_NEW");
    navigate("/new-wallet-step1");
  }

  return (
    <Box textAlign="center" fontSize="xl" bgGradient='linear(to-t, #481162, #131B63)'>
      <Grid minH="100vh" p={3}>
        <VStack spacing={4}>
          <Image mt={8} ml={16} mr={16}
                 src='https://res.cloudinary.com/shenmanz/image/upload/v1664741070/web3_lab/homepage_icon.png'
                 alt='Icon'/>
          <Box>
            <Heading mt={8} color='white'>欢迎来到</Heading>
            <Heading mb={4} color='white'>Web3.0 Lab</Heading>
          </Box>
          <HomePageBtn title='创建身份' subTitle='使用新的钱包' onClick={handleCreateNewWallet}/>
          <HomePageBtn title='恢复身份' subTitle='使用我已拥有的钱包' onClick={handleAddExisintWallet}/>
        </VStack>
      </Grid>
    </Box>
  );
}
