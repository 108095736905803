import * as React from 'react';
import { getBalanceAsync, getERC20TokenBalanceAsync } from '../reducer/appSlice';
import { RootState } from '../store/rootStore';
import { getProvider } from '../utils/etherUtils';
import { useAppDispatch, useAppSelector } from './store';

const useBalance = (register?: boolean) => {
    const { network } = useAppSelector((state: RootState) => state.app);
    const dispatch = useAppDispatch();
    React.useEffect(() => {
        if(!register) {
            return;
        }
        const provider = getProvider(network);
        //console.log("on")
        provider.on('block', (blockNumber) => {
          console.log("blockNumber", blockNumber);
          // dispatch(getBalanceAsync(""));
          // dispatch(getERC20TokenBalanceAsync());
        });
        return () => {
            //console.log("off")
            provider.off("block");
        };
    }, [network]);

    const updateBlance = () => {
        // dispatch(getBalanceAsync(""));
        // dispatch(getERC20TokenBalanceAsync());
    }

    return { updateBlance }
}
export default useBalance
