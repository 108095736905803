import React, {useEffect, useState} from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import WhiteSpace from "./WhiteSpace";
import FloatBottomBtn from "./FloatBottomBtn";

type BottomModalWithBottomProps = {
  children: React.ReactElement,
  btnTitle?: string,
  modalTitle?: string,
  onBtnClicked?: () => void,
  open: boolean
  onClose: () => void
}


const BottomModalWithBottom = (props: BottomModalWithBottomProps) => {

  return (
    <Modal isOpen={props.open} onClose={() => {
      if (props.onClose){
        props.onClose()
      }
    }}>
      <ModalOverlay/>
      <ModalContent
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          maxWidth: "768px",
          marginBottom: 0,
          backgroundColor: '#F7F8F9'
        }}
      >
        <ModalCloseButton
          style={{right: "inherit", left: "var(--chakra-space-3)", top: props.modalTitle ? 16 : 8}}
        />
        <ModalHeader style={{textAlign: "center"}}>{props.modalTitle ? props.modalTitle : ''}</ModalHeader>
        <ModalBody>
          {props.children}
        </ModalBody>
        <ModalFooter>
          <WhiteSpace height={44}/>
          <FloatBottomBtn
            title={props.btnTitle ? props.btnTitle : "下一步"}
            onClick={() => {
              if (props.onBtnClicked){
                props.onBtnClicked()
              }
            }}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default BottomModalWithBottom;
