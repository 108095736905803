import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {Text, Divider, Image} from "@chakra-ui/react";
import dayjs from "dayjs";
import FloatBottomBtn from "../../../components/FloatBottomBtn";
import NavigationBar from "../../../components/NavigationBar";
import WhiteSpace from "../../../components/WhiteSpace";
import {NEW_CONTRACT_PRICE} from "../../../config/system";
import {THEME} from "../../../config/theme";
import {useAppSelector} from "../../../hooks/store";
import {RootState} from "../../../store/rootStore";
import waiting from "../../../svg/waiting.svg";
import numeral from 'numeral'

const NewContractWaitingPage = () => {
  const navigate = useNavigate()
  const {pendingContract} = useAppSelector(
    (rootState: RootState) => rootState.app
  );

  useEffect(()=>{
    if (!pendingContract) {
      navigate('/my/wallet', {
        replace: true
      })
    }
  }, [])

  if (!pendingContract){
    return null
  }
  return (
    <React.Fragment>
      <NavigationBar title="详情"/>
      <Container>
        <Header>
          <Image
            className='image'
            boxSize="46px"
            src={waiting}
            alt="Icon"
          />
          <Text className="title" mt="8px">
            确认中
          </Text>
          <Text className="time" mt="8px">
            {" "}
            {dayjs(Number(pendingContract.timeStamp) * 1000).format(
              "YYYY年M月D日 HH:mm:ss"
            )}
          </Text>
        </Header>
        <WhiteSpace/>
        <InfoItem
          info={[
            {
              label: "代币名称",
              value: pendingContract.name
            },
            {
              label: "代币缩写",
              value: pendingContract.symbol
            },
            {
              label: "发行数量",
              value: `${pendingContract.value} ${pendingContract.symbol}`
            },
          ]}
        />
        <WhiteSpace/>
        <InfoItem
          info={[
            {
              label: "服务费",
              value: `NZ ${numeral(NEW_CONTRACT_PRICE).divide(100).format('$0,0.00')}`
            },
          ]}
        />
        <WhiteSpace/>
        <InfoItem
          info={[
            {
              label: "合约地址",
              value: pendingContract.address,
            },
          ]}
        />
        <WhiteSpace/>
      </Container>
      <FloatBottomBtn title="查看钱包" onClick={() => {
        navigate('/my/wallet', {
          replace: true
        })
      }}/>
    </React.Fragment>
  );
};

export default NewContractWaitingPage;

type InfoItemProps = {
  info: {
    label: string;
    value: string;
  }[];
};

const InfoItem = (props: InfoItemProps) => {
  return (
    <React.Fragment>
      <InfoItemContainer>
        {props.info.map((item, index) => {
          return (
            <React.Fragment>
              <div className="item">
                <Text className="left">{item.label}</Text>
                <Text className="right">{item.value}</Text>
              </div>
              {index !== props.info.length - 1 && <Divider/>}
            </React.Fragment>
          );
        })}
      </InfoItemContainer>
    </React.Fragment>
  );
};

const InfoItemContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  padding-bottom: 0;

  .item {
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
    flex-direction: row;
    justify-content: space-between;

    .left {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      font-feature-settings: "case" on;
      color: ${THEME.color.gray["#2-045"]};
    }

    .right {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      max-width: 70%;
      text-align: right;
      word-wrap: break-word;
      font-feature-settings: "case" on;
      color: ${THEME.color.gray["#4-085"]};
    }
  }
`;

const Container = styled.div`
  padding: 22px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon {
    font-size: 56px;
    background-color: #07c160;
    color: white;
    border-radius: 28px;
    padding: 12px;
  }

  .title {
    color: #07c160;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    font-feature-settings: "case" on;
  }

  .time {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    font-feature-settings: "case" on;
    color: ${THEME.color.gray["#2-045"]};
  }
`;
