import React, { useState } from "react";
import styled from "styled-components";
import { THEME } from "../config/theme";

type ItemType = {
  label: string;
  value: string;
};

type TabBarProps = {
  options: ItemType[];
  onChange: (value: string) => void;
  selected: string;
};

const TabBar = (props: TabBarProps) => {
  const [current, setCurrent] = useState<string>(props.selected);
  return (
    <Container num={props.options.length}>
      <div className="tab-container">
        {props.options.map((item, index) => (
          <div
            className="tab-item"
            key={index}
            onClick={() => {
              setCurrent(item.value);
              if (props.onChange) {
                props.onChange(item.value);
              }
            }}
          >
            <div className="label-wrap">
              <div
                className={`label${
                  item.value === current ? " label-selected" : ""
                }`}
              >
                {item.label}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

interface ContainerProps { 
  num: number;
}

const Container = styled.div<ContainerProps>`
  .tab-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    border-radius: 8px;
    background-color: rgba(118, 118, 128, 0.12);
    .tab-item {
      width: ${(props) => (1 / props.num * 100)}%;
      flex-shrink: 0;
      white-space: nowrap;
      text-align: center;
      position: relative;

      &:nth-child(1) {
        .label-wrap {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
      }

      &:nth-last-child(1) {
        .label-wrap {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;

          ::after {
            display: none;
          }

          .label {
            border-right: none;
          }
        }
      }
    }

    .label-wrap {
      background-color: rgba(118, 118, 128, 0.12);
      padding: 2px;

      ::after {
        content: "";
        width: 1px;
        background-color: rgba(60, 60, 67, 0.36);
        position: absolute;
        right: 0;
        top: 8px;
        height: 22px;
      }

      .label {
        line-height: 32px;
        font-size: 14px;
        color: ${THEME.color.gray["#2-045"]};
      }

      .label-selected {
        background-color: white;
        border-radius: 8px;
        color: ${THEME.color.gray["#4-085"]};
        border: 0.5px solid rgba(0, 0, 0, 0.04);
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12),
          0px 3px 1px rgba(0, 0, 0, 0.04);
      }
    }
  }
`;

export default TabBar;
