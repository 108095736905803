import * as React from 'react';
import { Box, HStack, Icon, VStack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useDispatch } from 'react-redux';
import { PURGE } from 'redux-persist';


export interface ISettingProps {
}

export interface ISettingItemProps {
  title: string,
  icon?: any,
  onpress?: () => void,
}

const SettingItem = ({ title, icon, onpress }: ISettingItemProps) => {
  return (
    <HStack w="100vw" p={4} justify="space-between" onClick={onpress}>
      {/* <Icon /> */}
      <Text>{title}</Text>
      <ChevronRightIcon />
    </HStack>
  );
};

export default function Setting (props: ISettingProps) {
const navigate = useNavigate();
const dispatch = useDispatch();

const handleLogout = () => {
  dispatch({type: PURGE, key: "root", result: () => {
    navigate("/create-or-add-wallet");
  }});
}

  return (
    <Box fontSize="lg">
        <VStack>
          <SettingItem title='Deploy a token' onpress={() => {
            navigate("/deploy-token")
          }} />
           <SettingItem title='Clear cache' onpress={handleLogout} />
        </VStack>
    </Box>
  );
}
