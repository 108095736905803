import * as React from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import useEncrypt from "../hooks/useEncrypt";

export interface IEncryptDiloagProps {
    onConfirm: (password: string) => void;
}

export default function EncryptDiloag(props: IEncryptDiloagProps) {
  const { encryptState, showEncryptDialog, updatePassword } = useEncrypt();

  const { showDialog, type, password } = encryptState;

  const handleDialogClose = () => {
    showEncryptDialog(false);
  };

  const handleOnInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    updatePassword(event?.target.value);

  const handleConfirmButtonClick = () => {
    showEncryptDialog(false);
    props.onConfirm(password);
  };

  return (
    <Modal onClose={handleDialogClose} size="xs" isOpen={showDialog}>
      <ModalBody />
      <ModalContent>
        <ModalHeader>
          {type === "INPUT_PASSWORD" ? "input your password" : "set a password"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            type="password"
            value={password}
            onChange={handleOnInputChange}
            variant="outline"
            placeholder="input password"
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleConfirmButtonClick}>Confirm</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
