import React, {useState} from "react";
import styled from 'styled-components'
import FloatBottomBtn from "../../components/FloatBottomBtn";
import NavigationBar from "../../components/NavigationBar";

import {useAppSelector} from "../../hooks/store";
import {RootState} from "../../store/rootStore";
import {THEME} from "../../config/theme";
import {
  Box,
  Grid,
  GridItem, Image,
  ListItem,
  Modal, ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  UnorderedList,
  Text, Button
} from "@chakra-ui/react";
import WhiteSpace from "../../components/WhiteSpace";
import {useNavigate} from "react-router-dom";

const NewWalletStep3 = () => {
  const {newChive} = useAppSelector((state: RootState) => state.app);
  const [showModal, setShowModal] = useState(true)
  const navigate = useNavigate()
  return (
    <div>
      <NavigationBar title='备份助记词'/>
      <Container>
        <div className='page-title'>
          备份记住词
        </div>
        <div className='page-desc'>
          请按照顺序抄写助记词，确保备份正确。
        </div>
        <Box mt={8}>
          <Grid templateColumns='repeat(3, 1fr)'>
            {newChive.seeds && newChive.seeds.split(' ').map((item, index) => {
              return (
                <GridItem key={`${item}_${index}`} className='item' h='60px'>
                  <div className='index'>{index + 1}</div>
                  <span className='word'>{item}</span>
                </GridItem>
              )
            })}

          </Grid>
        </Box>
        <WhiteSpace height={24}/>
        <UnorderedList>
          <ListItem className='hint'>妥善保管助记词至隔离网络的安全地方。</ListItem>
          <ListItem className='hint'>请勿将助记词在联网环境下分享和存储，比如 邮件、相册、社交应用等。</ListItem>
        </UnorderedList>
      </Container>
      <FloatBottomBtn title='已确认备份' onClick={() => {
        navigate('/new-wallet-step4')
      }}/>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}>
        <ModalOverlay/>
        <ModalContent>
          <ModalBody>
            <ModalContainer>
              <Image mt={8} ml={16} mr={16}
                     boxSize='40px'
                     src='https://res.cloudinary.com/shenmanz/image/upload/v1664856212/web3_lab/no_shot.png'
                     alt='Icon'/>
              <WhiteSpace height={8}/>
              <Text className='title'>请勿截屏</Text>
              <WhiteSpace height={16}/>
              <Text className='desc'>请不要通过截屏的方式进行备份，这将会增加助记
                词被盗和丢失的风险。图库一旦被恶意软件窃取，将会造成资产损失。</Text>
              <WhiteSpace height={32}/>
              <Button
                variant='solid'
                style={{
                  width: '100%',
                  backgroundColor: THEME.color.primary.default,
                  color: 'white'
                }}
                onClick={() => {
                  setShowModal(false)
                }}
              >
                我知道了
              </Button>
            </ModalContainer>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  )
}

const Container = styled.div`
  padding: 16px;

  .page-title {
    color: ${THEME.color.gray["#4-085"]};
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    font-feature-settings: 'case' on;
  }

  .page-desc {
    color: ${THEME.color.gray["#2-045"]};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    font-feature-settings: 'case' on;
    margin-top: 8px;
  }

  .item {
    margin: 1px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2px 8px;
    height: 60px;
    background: #F7F8F9;
    border: 1px solid #EAECF5;

    .index {
      text-align: right;
      width: 100%;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      font-feature-settings: 'case' on;
      color: ${THEME.color.gray["#2-045"]};
    }

    .word {
      color: ${THEME.color.gray["#4-085"]};
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
    }
  }

  .hint {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${THEME.color.gray["#2-045"]};
  }
`

const ModalContainer = styled(Box)`
  background-color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${THEME.color.gray["#4-085"]};
  }

  .desc {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${THEME.color.gray["#2-045"]};
  }
`

export default NewWalletStep3
