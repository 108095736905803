import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';

export interface ITokenItemProps {
    icon: any,
    balance: string,
    name: string,
    symbol: string,
    price: string,
    onClick?: () => void;
}

export default function TokenItem ({icon, balance, name, symbol, price, onClick}: ITokenItemProps) {
  return (
    <HStack width="100vw" onClick={onClick} p={2}>
      <HStack>
        <Box bg="blue.100" borderRadius="full" display="flex" justifyContent="center" alignItems="center" width="10" height="10">{icon}</Box>
        <VStack alignItems="flex-start">
            <Text>{name}</Text>
            <Text>{balance}{symbol}</Text>
        </VStack>
        <Box>
            <Text>{price}</Text>
        </Box>
      </HStack>
    </HStack>
  );
}
