import React from "react";
import styled from "styled-components";
import {HStack, Image, VStack, Text, Checkbox} from "@chakra-ui/react";
import {DEFAULT_WALLET_ICON} from "../Wallet";
import {THEME} from "../../../config/theme";
import {formatAddressWithDot} from "../../../utils/Helpers";

type WalletItemProps = {
  walletInfo: any,
  isSelected: boolean,
  onChecked: () => void,
  rightElement?: React.ReactElement
}

const WalletItem = (props: WalletItemProps) => {
  const {walletInfo, isSelected, onChecked} = props
  return (
    <Container onClick={() => {
      if (onChecked) {
        onChecked()
      }
    }}>
      <HStack>
        <Image
          className='image'
          boxSize="40px"
          src={walletInfo?.icon || DEFAULT_WALLET_ICON}
          alt="Icon"
        />
        <VStack alignItems='flex-start' flex={1}>
          <Text className='name'>{walletInfo.name || '钱包'}</Text>
          <Text className='address'>{formatAddressWithDot(walletInfo.address)}</Text>
        </VStack>
        {props.rightElement ? (
          props.rightElement
        ) : (
          <Checkbox
            size="md"
            colorScheme="green"
            isChecked={isSelected}
          ></Checkbox>
        )}

      </HStack>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 24px;

  .image {

  }

  .name {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    font-feature-settings: 'case' on;
    color: ${THEME.color.gray["#4-085"]};
  }

  .address {
    margin-top: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    font-feature-settings: 'case' on;
    max-width: 100%;
    overflow: hidden;
    word-wrap: anywhere;
    color: ${THEME.color.gray["#2-045"]};
  }
`

export default WalletItem
