import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MyPage from "./pages/my/My";
import NewContractWaitingPage from "./pages/my/newToken/Waiting";
import TopUp from "./pages/my/TopUp";
import TopUpHistory from "./pages/my/TopUpHistory";
import WalletSetting from "./pages/my/WalletSetting";
import PaymentPage from "./pages/Payment";
import PaymentSuccess from "./pages/PaymentSuccess";
import { RootState } from "./store/rootStore";
import MyToken from "./features/wallet/components/MyToken";
import SendToken from "./features/wallet/components/SendToken";
import DeployToken from "./features/wallet/components/DeployToken";
import Setting from "./features/setting//components/Setting";
import Homepage from "./pages/homepage/Homepage";
import {Container, Modal, ModalContent, ModalOverlay} from '@chakra-ui/react'
import NewWalletStep1 from "./pages/newWallet/NewWalletStep1";
import {THEME} from "./config/theme";
import NewWalletStep2 from "./pages/newWallet/NewWalletStep2";
import NewWalletStep3 from "./pages/newWallet/NewWalletStep3";
import NewWalletStep4 from "./pages/newWallet/NewWalletStep4";
import MyWallet from "./pages/my/Wallet";
import AddContract from "./pages/my/AddContract";
import Transactions from "./pages/my/Transactions";
import TransactionDetail from "./pages/my/TransactionDetail";
import SelectToken from "./pages/my/transfer/SelectToken";
import Transfer from "./pages/my/transfer/Transfer";
import NewToken from "./pages/my/newToken/TokenInfo";
import RestoreWallet from "./pages/my/RestoreWallet";
import Lottie from 'lottie-react-web'
import loading from './json/loading.json'

export const App = () => {
  const { currentWallet, showToast } = useSelector((rootState: RootState) => rootState.app);
  return (
    <Container
      maxW={768}
      pl={0}
      pr={0}
      minH="100vh"
      style={{ backgroundColor: THEME.color.background.gray }}
    >
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              !!currentWallet ? (
                <Navigate to="/my/wallet" />
              ) : (
                <Navigate to="/homepage" />
              )
            }
          />
          {/* <Route path="counter" element={<Counter />} /> */}
          <Route path="/homepage" element={<Homepage />} />
          <Route path="/new-wallet-step1" element={<NewWalletStep1 />} />
          <Route path="/new-wallet-step2" element={<NewWalletStep2 />} />
          <Route path="/new-wallet-step3" element={<NewWalletStep3 />} />
          <Route path="/new-wallet-step4" element={<NewWalletStep4 />} />
          <Route path="/restore-wallet" element={<RestoreWallet />} />
          <Route path="/my/wallet" element={<MyWallet />} />
          <Route path="/my/myPage" element={<MyPage />} />
          <Route path="/my/walletSetting" element={<WalletSetting />} />
          <Route path="/my/select-token" element={<SelectToken />} />
          <Route path="/my/transfer" element={<Transfer />} />
          <Route path="/my/new-token" element={<NewToken />} />
          <Route path="/my/waiting" element={<NewContractWaitingPage />} />
          <Route path="/my/new-contract" element={<AddContract />} />
          <Route path="/my/transactions" element={<Transactions />} />
          <Route path="/my/topup" element={<TopUp />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/my/topUpHistory" element={<TopUpHistory />} />
          <Route
            path="/my/transaction-detail"
            element={<TransactionDetail />}
          />
          <Route path="/my-token" element={<MyToken />} />
          <Route path="/send-token" element={<SendToken />} />
          <Route path="/deploy-token" element={<DeployToken />} />
          <Route path="/setting" element={<Setting />} />
        </Routes>
      </BrowserRouter>
      <Modal
        closeOnOverlayClick={false}
        isCentered
        isOpen={!!showToast}
        onClose={() => {
        }}
      >
        <ModalOverlay />
        <ModalContent
          style={{ display: "inline-block", width: "fit-content", padding: 16 }}
        >
          <Lottie
            height={60}
            width={60}
            options={{
              animationData: loading,
            }}
          />
          <div
            style={{
              color: THEME.color.gray["#4-085"],
              marginTop: 8,
              fontWeight: 500,
              fontSize: 18,
              textAlign: "center"
            }}
          >
            {typeof showToast === "string" ? showToast : "确认中..."}
          </div>
        </ModalContent>
      </Modal>
    </Container>
  );
};
