import React from "react";
import styled from 'styled-components'
import {ChevronLeftIcon} from "@chakra-ui/icons";
import {useNavigate} from "react-router-dom";

type NavigationBarPropsType = {
  title?: string,
  showBackIcon?: boolean
}

const NavigationBar = (props: NavigationBarPropsType)=>{
  const navigate = useNavigate();
  return(
    <Container>
      <div className='placeholder'></div>
      <div className='navigation'>
        {(props.showBackIcon === undefined || props.showBackIcon) && (
          <div className='left-icon' onClick={()=>{
            navigate(-1)
          }}>
            <ChevronLeftIcon color='white' fontSize={36}/>
          </div>
        )}
        <div className='title' style={{marginLeft: (props.showBackIcon === undefined || props.showBackIcon) ? '-52px' : '0px'}}>
          {props.title}
        </div>
      </div>
    </Container>
  )
}

export default NavigationBar

const Container = styled.div`
  .placeholder{
    height: 44px;
  }
  .navigation{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 44px;
    z-index: 999;
    background: linear-gradient(180deg, #131B63 -18.9%, #481162 119.73%);
    display: flex;
    flex-direction: row;
    align-items: center;
    .left-icon{
      width: 36px;
      height: 36px;
      margin-left: 16px;
      z-index: 1;
    }
    .title{
      text-align: center;
      flex: 1;
      color: white;
      margin-left: -52px;
      font-size: 18px;
      font-weight: 400;
    }
  }

`
