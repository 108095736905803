import {extendTheme} from "@chakra-ui/react"

const theme = extendTheme({
  colors: {
    brand: {
      100: "#261863",
      900: "#261863",
    },
  },
})

export default theme
