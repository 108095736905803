import {Image} from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import styled from 'styled-components'
import {THEME} from "../../../config/theme";
import income from "../../../svg/income.svg";
import outcome from "../../../svg/export.svg";
import numeral from 'numeral'
import {formatAddressWithDot} from "../../../utils/Helpers";

type orderItemProps = {
  order: any
}

export enum OrderType {
  'TOP_UP' = 'TOP_UP',
  'CONSUME' = 'CONSUME',
}

export enum OrderStatus {
  'CREATED' = 'CREATED',
  'PAID' = 'PAID',
  'CANCELED' = 'CANCELED',
  'EXPIRED' = 'EXPIRED',
  'ERROR' = 'ERROR'
}

const OrderItem = (props: orderItemProps) => {
  const {order} = props
  return (
    <Container>
      <div className='left'>
        <Image
          className='image'
          boxSize="24px"
          src={order.type === OrderType.TOP_UP ? income : outcome}
          alt="Icon"
        />
      </div>
      <div className='center'>
        <div className='desc'>
          {order.type === OrderType.TOP_UP ? '充值' : `服务费-${formatAddressWithDot(order.transInfo.transHash)}`}
        </div>
        <div className='date'>
          {dayjs(order.createdAt).format('DD/MM/YYYY HH:mm:ss')}
        </div>
      </div>
      <div className='right'>
        <div>
          {order.type === OrderType.CONSUME ? (
            <span className='consume'>
              -{numeral(order.price).divide(100).format('$0,0.00')}
            </span>
          ) : (
            <span className='top-up'>
              {numeral(order.price).divide(100).format('$0,0.00')}
            </span>
          )}
        </div>
        <div>
          {order.status === OrderStatus.CREATED ? (
            <span className='pending'>Pending</span>
          ) : (
            <span>

            </span>
          )}
        </div>
      </div>
    </Container>
  )
}

export default OrderItem

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 16px;

  .left {
    width: 36px;
  }

  .center {
    flex: 1;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    font-feature-settings: 'case' on;
    border-bottom: 1px solid #EAECF5;
    padding-bottom: 16px;
    .desc {
      color: ${THEME.color.gray["#4-085"]};
    }

    .date {
      color: ${THEME.color.gray["#2-045"]};
    }
  }

  .right {
    width: 50px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-align: right;
    font-feature-settings: 'case' on;
    border-bottom: 1px solid #EAECF5;
    padding-bottom: 16px;
    .consume {
      color: ${THEME.color.primary.danger}
    }
    .pending{
      color: ${THEME.color.primary.danger}
    }

    .top-up {
      color: ${THEME.color.gray["#4-085"]};
    }
  }
`


