export class Helpers {
    private static _instance: Helpers;

    private constructor(network: string) {
    }

    public static Instance(network: string) {
        // Do you need arguments? Make it a regular static method instead.
        return this._instance || (this._instance = new this(network));
    }
}

export const shuffle = <T>(a: T[]) => {
    if (!a || a.length === 0) {
        return []
    }
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

export const formatBalance = (balance)=>{
    if (!balance){
        return 0
    }
    if (balance > 1E10 || balance.length > 10){
        return Number(balance).toExponential(8)
    }
    return  balance
}


export const formatAddressWithDot = (address: string | undefined) => {
    if (!address) {
        return ''
    }
    return `${address.substring(0, 9)}...${address.substring(
        address.length - 10
    )}`
}

export const isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
