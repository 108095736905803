import * as React from 'react';
import {RootState} from '../store/rootStore';
import {erc20GasFee, getProvider} from '../utils/etherUtils';
import {useAppSelector} from './store';
import {deriveAccountFromPrivateKey} from '../utils/ethereumUtil';
import {utils} from 'ethers';
import {formatUnits, parseEther, parseUnits} from 'ethers/lib/utils';

let provider:any = null

const useTransfer = () => {
  const {network, currentWallet: walletData} = useAppSelector((state: RootState) => state.app);
  const [gasPrice, setGasPrice] = React.useState<string>();
  const [gasPriceLoading, setGasPriceLoading] = React.useState(false);

  if (!provider) {
    provider = getProvider(network);
  }


  const transfer = async (toAddress: string, value: string, privateKey: string) => {
    let wallet = deriveAccountFromPrivateKey(privateKey);
    const tx = {
      to: toAddress,
      value: utils.parseEther(value)
    };
    await wallet.wallet.signTransaction(tx);
    const connectedWallet = wallet.wallet.connect(provider);
    return await connectedWallet.sendTransaction(tx);
  }

  const getGasPrice = async () => {
    setGasPriceLoading(true);
    setGasPrice(formatUnits(await provider.getGasPrice(), "gwei"));
    setGasPriceLoading(false);
  }

  const getEstimateGas = async (to: string, value: string) => {
    setGasPriceLoading(true);
    const result = await erc20GasFee(walletData?.address || '', to, value)
    if (result === 'NA') {
      setGasPrice('')
    } else {
      setGasPrice(result)
    }
    setGasPriceLoading(false);
  }

  return {transfer, getGasPrice, gasPrice, gasPriceLoading, getEstimateGas}
}
export default useTransfer;
