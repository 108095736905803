import { ArrowForwardIcon } from "@chakra-ui/icons";
import React from "react";
import styled from "styled-components";
import {THEME} from "../../../config/theme";


type HomePageBtnProps = {
  title: string
  subTitle: string
  onClick: ()=>void
}

const HomePageBtn = (props: HomePageBtnProps)=>{
  return (
    <Container onClick={()=>{
      if (props.onClick){
        props.onClick()
      }
    }}>
      <div className='left'>
        <div className='title'>{props.title}</div>
        <div className='sub-title'>{props.subTitle}</div>
      </div>
      <div className='right'>
        <ArrowForwardIcon color='white'></ArrowForwardIcon>
      </div>
    </Container>
  )
}

export default HomePageBtn

const Container = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
  .left{
    color: ${THEME.color.primary.default};
    text-align: left;
    .title{
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
    .sub-title{
      color: ${THEME.color.gray["#2-045"]};
      font-size: 12px;
      line-height: 17px;
    }
  }
  
  .right{
    height: 36px;
    width: 36px;
    border-radius: 18px;
    background: linear-gradient(141.41deg, #8462E1 2.34%, #55389B 42.61%, #351B6F 81.39%);
    box-shadow: 2px 4px 9px #985884;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
