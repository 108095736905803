import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {StringMap} from "ts-jest";
import {getContract, getEtherscanProvider, getProvider} from '../utils/etherUtils';
import {RootState} from '../store/rootStore';
import {formatEther} from 'ethers/lib/utils';
import {ethers} from 'ethers';
import {chainSlice} from "./chainSlice";

export interface WalletState {
  address?: string,
  privateKey?: string,
  balance?: string,
  name?: string,
  icon?: string,
  erc20Tokens?: ERC20Token[],
  history?: MyTransaction[],
}

export interface MyTransaction {
  hash: string,
  // Not optional (as it is in Transaction)
  from: string,
  to?: string,
  timestamp?: number,
}

export interface ERC20Token {
  contractAddress: string,
  name?: string,
  symbol?: string,
  balance?: string;
  decimal?: string;
}

export interface NewChiveState {
  seeds: string,
  password: string,
  name?: string
}

export interface PendingContract {
  hash: string,
  value: string,
  timeStamp: string,
  name: string,
  symbol: string,
  address: string
}

export interface TransferType {
  type?: 'ETH' | 'ERC20'
  tokenName?: string,
  balance?: string,
  from?: string,
  to?: string,
  value?: string,
  symbol?: string
  note?: string,
  decimal?: string,
  contractAddress?: string,
  gas?: string,
  hash?: string,
  confirmations?: string,
  timeStamp?: string
}

export interface afterPayType {
  route: string,
  params?: any
}

export interface AppState {
  // 网络切换
  network: string,
  // 钱包地址
  address: string,
  // 拥有钱包
  wallets: WalletState[],
  // 转账信息 （跳转支付后，恢复数据用）
  transfer: TransferType | null,
  // 当前选择钱包
  currentWallet: WalletState | null,
  // 当前选择的智能合约，转账后，刷新合约的转账历史记录，需要使用
  currentContract?: ERC20Token | null,
  // 新韭菜注册信息记录
  newChive: NewChiveState,
  // 控制UI是否出现loading toast
  showToast: boolean | string,
  // 支付完成后，继续的流程，目前已经写入支付回调中，暂无大用，只是表明是否是通过支付后继续中断的流程
  afterPay?: afterPayType | undefined,
  // 转账后的临时记录，为了更好的用户交互，刷新出相应记录后会自动删除
  pendingTrans?: TransferType[],
  // 同上，用于发布新的智能合约
  pendingContract?: PendingContract
}

const initChiveState = {
  seeds: '',
  password: '',
}

const initialState: AppState = {
  network: 'goerli',
  address: '',
  wallets: [],
  currentWallet: null,
  transfer: null,
  newChive: initChiveState,
  showToast: false,
  pendingTrans: [],
  currentContract: null
}

export const getBalanceAsync = createAsyncThunk<string, string, { state: RootState }>(
  'ethers/getBalanceAsync',
  async (address: string, {getState}) => {
    const {app} = getState();
    const {network, currentWallet} = app;
    const provider = getProvider(network);
    const notNullAddress = currentWallet?.address || address
    const balance = await provider.getBalance(notNullAddress);
    return formatEther(balance);
  }
);

export const getHistoryAsync = createAsyncThunk<MyTransaction[], string, { state: RootState }>(
  'ethers/getHistory',
  async (address: string, {getState}) => {
    const {app} = getState();
    const {network, currentWallet} = app;
    const provider = getEtherscanProvider(network);
    const notNullAddress = currentWallet?.address || address
    const history = await provider.getHistory(notNullAddress);
    return history.map(tx => ({
      hash: tx.hash,
      from: tx.from,
      to: tx.to,
      timestamp: tx.timestamp
    })).sort((a, b) => (b.timestamp ? b.timestamp : 0) - (a.timestamp ? a.timestamp : 0));
  }
);

export const addERC20Token = createAsyncThunk<ERC20Token, string, { state: RootState }>(
  'ethers/addERC20Token',
  async (contractAddress: string, {getState}) => {
    const {app} = getState();
    const {network, currentWallet} = app;
    //const { address } = wallet;
    const contract = getContract(contractAddress, network);

    // Get the ERC-20 token name
    const name = await contract.name();

    // Get the ERC-20 token symbol (for tickers and UIs)
    const symbol = await contract.symbol();


    // Get the balance of an address
    const balance = await contract.balanceOf(currentWallet?.address);
    // { BigNumber: "3118000455884268201631" }

    // Format the DAI for displaying to the user
    const formatBalance = ethers.utils.formatUnits(balance, 18);

    const erc2Token: ERC20Token = {
      contractAddress,
      name,
      symbol,
      balance: formatBalance
    }
    return erc2Token;
  }
);


export const getERC20TokenBalanceAsync = createAsyncThunk<ERC20Token[], void, { state: RootState }>(
  'ethers/getERC20TokenBalanceAsync',
  async (_, {getState}) => {
    const {app} = getState();
    const {network, currentWallet} = app;
    const erc20Tokens = currentWallet?.erc20Tokens || []
    const updatedErc2Tokens: ERC20Token[] = [];

    for (const erc20Token of erc20Tokens) {
      //const { address } = wallet;
      const contract = getContract(erc20Token.contractAddress, network);

      // Get the balance of an address
      const balance = await contract.balanceOf(currentWallet?.address);
      // { BigNumber: "3118000455884268201631" }

      // Format the DAI for displaying to the user
      const formatBalance = ethers.utils.formatUnits(balance, 18);
      updatedErc2Tokens.push({
        ...erc20Token,
        balance: formatBalance,
      })
    }

    // const erc2Token: ERC20Token = {
    //   contractAddress,
    //   balance: formatBalance
    // }
    return updatedErc2Tokens;
  }
);


export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setPendingContract: (state, action: PayloadAction<PendingContract>) => {
      state.pendingContract = action.payload
    },
    setCurrentContract: (state, action: PayloadAction<ERC20Token | null>) => {
      state.currentContract = action.payload
    },
    setPendingTrans: (state, action: PayloadAction<TransferType>) => {
      const {payload} = action
      const tempData: TransferType = {
        to: payload.to,
        from: payload.from,
        value: `${payload.value}`,
        hash: payload.hash,
        confirmations: payload.confirmations,
        timeStamp: `${Date.now() / 1000}`
      }
      state.pendingTrans = [...state.pendingTrans || [], tempData]
    },
    setAfterPayPath: (state, action: PayloadAction<afterPayType | undefined>) => {
      state.afterPay = action.payload
    },
    setShowToast: (state, action: PayloadAction<boolean | string>) => {
      state.showToast = action.payload
    },
    changeNetwork: (state, action: PayloadAction<string>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.network = action.payload;
    },
    addWallet: (state, action: PayloadAction<WalletState>) => {
      state.currentWallet = action.payload;
      const existInWallets = state.wallets?.filter(item => item.address === action.payload.address)
      if (!existInWallets || existInWallets.length === 0) {
        if (state.wallets) {
          state.wallets.push(action.payload)
        } else {
          state.wallets = [action.payload]
        }
      }
      state.newChive = initChiveState
    },
    switchWallet: (state, action: PayloadAction<string>) => {
      if (action.payload === state.currentWallet?.address) {
        return
      }
      const existInWallets = state.wallets?.filter(item => item.address === action.payload)
      if (existInWallets && existInWallets.length > 0) {
        state.currentWallet = existInWallets[0]
      }
    },
    updateCurrentWallet: (state, action: PayloadAction<WalletState>) => {
      const newWalletState = {
        ...state.currentWallet,
        ...action.payload
      }
      state.currentWallet = newWalletState
      const otherWallets = state.wallets?.filter(item => item.address !== state.currentWallet?.address)
      if (otherWallets && otherWallets.length > 0) {
        state.wallets = [
          ...otherWallets,
          newWalletState
        ]
      } else {
        state.wallets = [newWalletState]
      }
    },
    updateWallet: (state, action: PayloadAction<WalletState>) => {
      if (action.payload.address === state.currentWallet?.address) {
        appSlice.caseReducers.updateCurrentWallet(state, action)
      } else {
        const otherWallets = state.wallets.filter(item => item.address !== action.payload.address)
        console.log(otherWallets)
        state.wallets = state.wallets?.map(item => {
          if (item.address === state.currentWallet?.address) {
            return action.payload
          } else {
            return item
          }
        })
      }
    },
    updateToken: (state, action: PayloadAction<any>) => {
      if (state.currentWallet) {
        state.currentWallet.erc20Tokens = action.payload
      }
    },
    deleteCurrentWallet: (state) => {
      const otherWallets = state.wallets?.filter(item => item.address !== state.currentWallet?.address)
      if (otherWallets && otherWallets.length > 0) {
        state.currentWallet = otherWallets[0]
        state.wallets = otherWallets
      } else {
        state.currentWallet = null
        state.wallets = []
      }
    },
    deleteWallet: (state, action: PayloadAction<WalletState>) => {
      if (action.payload.address === state.currentWallet?.address) {
        appSlice.caseReducers.deleteCurrentWallet(state)
      } else {
        const otherWallets = state.wallets.filter(item => item.address !== action.payload.address)
        state.wallets = [...otherWallets]
      }
    },
    // 新韭菜创建钱包
    newChive: (state, action: PayloadAction<NewChiveState>) => {
      state.newChive = action.payload
    },
    setTransfer: (state, action: PayloadAction<TransferType | null>) => {
      if (action.payload) {
        if (!state.transfer) {
          state.transfer = action.payload
        } else {
          state.transfer = {
            ...state.transfer,
            ...action.payload
          }
        }
      } else {
        state.transfer = null
      }
    },
    clearTransfer: (state) => {
      state.transfer = null
    }
  },
  extraReducers: (builder) => {

    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getBalanceAsync.fulfilled, (state, action) => {
      // Add user to the state array
      if (state.currentWallet) {
        state.currentWallet.balance = action.payload
      }
    }).addCase(getBalanceAsync.rejected, (state) => {
      //state.status = 'failed';
    }).addCase(getBalanceAsync.pending, (state) => {
      //state.status = 'failed';
    }).addCase(getHistoryAsync.fulfilled, (state, action) => {
      // Add user to the state array
      if (state.currentWallet) {
        state.currentWallet.history = action.payload
      }
    }).addCase(getHistoryAsync.rejected, (state) => {
      //state.status = 'failed';
    }).addCase(getHistoryAsync.pending, (state) => {
      //state.status = 'failed';
    }).addCase(addERC20Token.fulfilled, (state, action) => {
      // Add user to the state array
      if (state.currentWallet) {
        if (state.currentWallet.erc20Tokens) {
          const filteredResult = state.currentWallet.erc20Tokens.filter(item => item.contractAddress !== action.payload.contractAddress)
          state.currentWallet.erc20Tokens = [...filteredResult, action.payload];
        } else {
          state.currentWallet.erc20Tokens = [action.payload];
        }
      }

    }).addCase(addERC20Token.rejected, (state) => {
      //state.status = 'failed';
    }).addCase(addERC20Token.pending, (state) => {
      //state.status = 'failed';
    }).addCase(getERC20TokenBalanceAsync.fulfilled, (state, action) => {
      // Add user to the state array
      if (state.currentWallet) {
        state.currentWallet.erc20Tokens = action.payload;
      }
    }).addCase(getERC20TokenBalanceAsync.rejected, (state) => {
      //state.status = 'failed';
    }).addCase(getERC20TokenBalanceAsync.pending, (state) => {
      //state.status = 'failed';
    })
    // 获取余额后更新state
    builder.addMatcher(chainSlice.endpoints.getBalanceByAddress.matchFulfilled, (state, action) => {
      if (state.currentWallet) {
        state.currentWallet.balance = ethers.utils.formatUnits(action.payload.result, 18)
      }
    })

    // 获取流水信息后更新 pending transaction list
    builder.addMatcher(chainSlice.endpoints.transactionsByAddress.matchFulfilled, (state, action) => {
      const transList = action.payload.result
      if (state.pendingTrans && state.pendingTrans.length > 0 && transList && transList.length > 0) {
        const newPending: TransferType[] = []
        for (const item of state.pendingTrans) {
          const inTrans = transList.filter(_item => _item.hash === item.hash).length > 0
          if (!inTrans) newPending.push(item)
        }
        state.pendingTrans = newPending
      }
    })

    // 获取token信息后，更新pending Contract
    builder.addMatcher(chainSlice.endpoints.checkTokenByWallet.matchFulfilled, (state, action) => {
      if (state.pendingContract){
        const contractList = action.payload.result
        if (contractList.filter(item=>item.contractAddress.toUpperCase()===state.pendingContract?.address?.toUpperCase()).length > 0){
          state.pendingContract = undefined
        }
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const {
  setCurrentContract,
  changeNetwork,
  addWallet,
  newChive,
  setTransfer,
  clearTransfer,
  setShowToast,
  updateCurrentWallet,
  deleteCurrentWallet,
  switchWallet,
  updateToken,
  updateWallet,
  deleteWallet,
  setAfterPayPath,
  setPendingTrans,
  setPendingContract
} = appSlice.actions

export default appSlice.reducer
